/* eslint-disable no-console */
import { useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import type * as History from 'history';

import { browserOnly } from '@farmersdog/utils';

export function useScrollRestore() {
  const previousLocation = useRef<History.Location>();
  const location = useLocation();

  const restoreScroll = useCallback((top = 0, left = 0) => {
    browserOnly(window => {
      window.scrollTo({ top, left, behavior: 'instant' });
    });
  }, []);

  useEffect(() => {
    const previousPathname = previousLocation?.current?.pathname;
    const previousHash = previousLocation?.current?.hash;
    const { hash, pathname } = location;

    browserOnly(window => {
      if (previousPathname === pathname && previousHash === hash) {
        return;
      }

      if (!hash) {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        return;
      }

      const node = window.document.querySelector(location.hash);

      if (!node) {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        return;
      }

      node.scrollIntoView({ behavior: 'auto', block: 'start' });
    });
  }, [location, previousLocation]);

  useEffect(() => {
    previousLocation.current = location;
  }, [location, previousLocation]);

  return { restoreScroll };
}

export default useScrollRestore;
