import type { SVGProps, FunctionComponent } from 'react';
import type { LinkProps } from '@farmersdog/corgi';
import { emails, links } from '@farmersdog/constants';
import CellPhoneSvg from './assets/cell-phone.svg?react';
import FacebookSvg from './assets/facebook.svg?react';
import HandshakeIcon from './assets/handshake.svg?react';
import InstagramSvg from './assets/instagram.svg?react';
import FacebookNewSvg from './assets/facebook-new.svg?react';
import InstagramNewSvg from './assets/instagram-new.svg?react';
import MailSvg from './assets/mail.svg?react';
import MediaInquirySvg from './assets/media-inquiry.svg?react';
import Tiktok from './assets/tiktok.svg?react';

interface ContactLink extends LinkProps {
  label?: string;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

interface ConnectLink {
  inquiries: ContactLink[];
  social: ContactLink[];
}

interface UseContactLinksProps {
  isAccountPage: boolean;
}

export function useContactLinks({
  isAccountPage,
}: UseContactLinksProps): (ContactLink | undefined)[] {
  return [
    {
      icon: MailSvg,
      href: `mailto:${emails.EMAIL_HELP}`,
      label: emails.EMAIL_HELP,
    },
    {
      href: 'tel:+16467807957',
      icon: CellPhoneSvg,
      label: '(646)-780-7957',
    },
    {
      href: links.LINK_IG,
      icon: InstagramSvg,
      label: 'Instagram',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      href: links.LINK_FB,
      icon: FacebookSvg,
      label: 'Facebook',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      href: `mailto:${emails.EMAIL_PRESS}`,
      icon: MediaInquirySvg,
      label: 'Media Inquiries',
    },
    {
      href: links.LINK_PARTNERSHIP_INQUIRIES,
      target: '_blank',
      icon: HandshakeIcon,
      label: 'Partnership Inquiries',
    },
    isAccountPage
      ? {
          href: `mailto:${emails.EMAIL_VET}`,
          icon: MailSvg,
          label: emails.EMAIL_VET,
        }
      : undefined,
  ];
}

interface SupportLinksArgs {
  isAccountPage: boolean;
}

export function useSupportLinks({
  isAccountPage,
}: SupportLinksArgs): (ContactLink | undefined)[] {
  return [
    {
      href: `mailto:${emails.EMAIL_HELP}`,
      label: isAccountPage ? emails.EMAIL_VET : emails.EMAIL_HELP,
    },
    {
      href: 'tel:+16467807957',
      label: '(646)-780-7957 - Call or Text',
    },
  ];
}
export function useConnectLinks(): ConnectLink | undefined {
  return {
    inquiries: [
      {
        href: `mailto:${emails.EMAIL_PRESS}`,
        label: 'Media Inquiries',
      },
      {
        href: links.LINK_PARTNERSHIP_INQUIRIES,
        target: '_blank',
        label: 'Partnership Inquiries',
      },
    ],
    social: [
      {
        href: links.LINK_IG,
        icon: InstagramNewSvg,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        href: links.LINK_FB,
        icon: FacebookNewSvg,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        href: links.LINK_TK,
        icon: Tiktok,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ],
  };
}
