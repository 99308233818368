import type { FormFieldArray } from '../../FormFields';
import { FieldType } from '../../FormFields';

export enum LoginFormFields {
  email = 'email',
  password = 'password',
}

export const loginFormFieldsData: FormFieldArray<LoginFormFields> = [
  {
    name: LoginFormFields.email,
    type: FieldType.Text,
    label: 'Contact Email',
    inputMode: 'email',
  },
  {
    name: LoginFormFields.password,
    type: FieldType.Password,
    label: 'Password',
  },
];
