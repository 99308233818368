import type { TextProps } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';
import styles from './SectionTitle.module.css';

const mobileTitleVariant = 'heading-22';
const mobileSubtitleVariant = 'heading-12';

const desktopTitleVariant = 'heading-28';
const desktopSubtitleVariant = 'heading-16';

interface SectionTitleProps {
  title: string;
  subtitle: string;
}

interface TitleAndSubtitlePairProps {
  title: SectionTitleProps['title'];
  subtitle: SectionTitleProps['subtitle'];
  variants: {
    title: TextProps['variant'];
    subtitle: TextProps['variant'];
  };
}

function TitleAndSubtitlePair({
  title,
  subtitle,
  variants,
}: TitleAndSubtitlePairProps) {
  return (
    <>
      <Text className={styles.title} as="h3" bold variant={variants.title}>
        {title}
      </Text>
      <Text
        className={styles.subtitle}
        color="charcoal-3"
        as="h4"
        variant={variants.subtitle}
      >
        {subtitle}
      </Text>
    </>
  );
}

export function SectionTitle(props: SectionTitleProps) {
  return (
    <>
      <div className={styles.mobile}>
        <TitleAndSubtitlePair
          {...props}
          variants={{
            title: mobileTitleVariant,
            subtitle: mobileSubtitleVariant,
          }}
        />
      </div>
      <div className={styles.desktop}>
        <TitleAndSubtitlePair
          {...props}
          variants={{
            title: desktopTitleVariant,
            subtitle: desktopSubtitleVariant,
          }}
        />
      </div>
    </>
  );
}
