import type { ReactNode } from 'react';

import type { PublicHeaderProps } from '../PublicHeader';
import { PublicHeader } from '../PublicHeader';
import { FullFooter } from '../FullFooter';
import styles from './PublicPage.module.css';
import classNames from 'classnames';

interface PageProps extends PublicHeaderProps {
  children: ReactNode;
  hideAppBar?: boolean;
  hideFooter?: boolean;
  blueberryBackground?: boolean;
}

export function PublicPage({
  children,
  showCTA,
  hideAppBar = false,
  hideFooter = false,
  blueberryBackground = false,
}: PageProps) {
  return (
    <div
      className={classNames([
        blueberryBackground ? styles.blueberryBackground : '',
      ])}
    >
      {hideAppBar && (
        <>
          <div> {children}</div>
          {!hideFooter && <FullFooter />}
        </>
      )}
      {!hideAppBar && (
        <>
          <PublicHeader showCTA={showCTA} />
          {children}
          {!hideFooter && <FullFooter />}
        </>
      )}
    </div>
  );
}
