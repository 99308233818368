import { Button, Divider, Grid, Text } from '@farmersdog/corgi-x';

import { FormFields } from '../../../FormFields';
import { CheckboxWithText } from '../../../CheckboxWithText';
import { PracticeInformation } from '../../../PracticeInformation';

import { useFormik } from 'formik';

import { TrialDiscountsContainer } from '../TrialDiscountsContainer';
import type { OrganizationInput, Position } from '../../../../graphql/types';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';

import type { ReviewInfoFormValues } from './assets';
import {
  ReviewInfoFormFields,
  getInitialFormValues,
  reviewInfoFormFieldsData,
  reviewInfoFormValidationSchema,
} from './assets';

import styles from './ReviewInfo.module.css';

interface ReviewInfoProps {
  organization: OrganizationInput | undefined;
  position: Position | undefined;
  onUpdate: () => void;
  onRequestDiscount: (values: ReviewInfoFormValues) => void;
  isUpdate?: boolean;
}

interface ResponsiveReviewInfo extends ReviewInfoProps {
  isMobile?: boolean;
}

const ResponsiveReviewInfo = ({
  isMobile = false,
  organization,
  position,
  onUpdate,
  isUpdate,
  onRequestDiscount,
}: ResponsiveReviewInfo) => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const formik = useFormik({
    initialValues: getInitialFormValues({ position }),
    validationSchema: reviewInfoFormValidationSchema,
    onSubmit: (values, { resetForm }) => {
      onRequestDiscount(values);
      resetForm();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const name = organization?.name;
  const address = `${organization?.streetAddress1}, ${organization?.streetAddress2}, ${organization?.city}, ${organization?.state}, ${organization?.zip}`;
  const disableButton = !formik.dirty || formik.isSubmitting || !formik.isValid;

  return (
    <TrialDiscountsContainer>
      <form onSubmit={handleSubmit}>
        <Text
          as="p"
          topSpacing="none"
          bottomSpacing={isMobile ? 'md' : 'lg'}
          variant={isMobile ? 'article-16' : 'article-20'}
        >
          {isUpdate
            ? 'We’ll update your vet profile with this practice and role.'
            : `Please verify that we have up-to-date details about where you work.
          We’ll use this to verify your status as a vet professional.`}
        </Text>
        <PracticeInformation
          name={name}
          address={address}
          onUpdate={onUpdate}
        />
        {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
        <FormFields formik={formik} fieldData={reviewInfoFormFieldsData} />
        <Text as="p" variant="article-16">
          The discount cannot be used in conjunction with any other promotions,
          offers or discounts. The discount may be rescinded at any time, for
          any reason, in TFD’s sole discretion.
        </Text>
        <Text as="p" variant="article-16">
          The discount available for eligible veterinary professionals and
          support staff under this program can only be applied to purchases of
          The Farmer’s Dog, Inc. products. The discount cannot be redeemed for
          cash, cash equivalents, or any other monetary sum.
        </Text>
        <CheckboxWithText
          bold
          maxWidth
          textVariant="heading-16"
          checked={formik.values[ReviewInfoFormFields.acceptedPrivacyPolicy]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors[ReviewInfoFormFields.acceptedPrivacyPolicy]}
          isTouched={formik.touched[ReviewInfoFormFields.acceptedPrivacyPolicy]}
          fieldName={ReviewInfoFormFields.acceptedPrivacyPolicy}
          labelContent={<>I agree to the above discount terms and conditions</>}
        />
        <Divider
          color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
          spacing={48}
          width={1}
        />
        <Grid
          justifyContent={{
            lg: 'flex-end',
          }}
        >
          <Button
            type="submit"
            variant={
              showPortalUXOverhaul
                ? 'primary-mini-kale-experimental'
                : 'primary'
            }
            className={styles.button}
            aria-disabled={disableButton}
          >
            Request Discount
          </Button>
        </Grid>
      </form>
    </TrialDiscountsContainer>
  );
};

export const ReviewInfo = ({
  organization,
  position,
  onUpdate,
  isUpdate = false,
  onRequestDiscount,
}: ReviewInfoProps) => {
  return (
    <div>
      <div className={styles.mobile}>
        <ResponsiveReviewInfo
          isMobile
          position={position}
          organization={organization}
          onUpdate={onUpdate}
          isUpdate={isUpdate}
          onRequestDiscount={onRequestDiscount}
        />
      </div>
      <div className={styles.desktop}>
        <ResponsiveReviewInfo
          position={position}
          organization={organization}
          onUpdate={onUpdate}
          isUpdate={isUpdate}
          onRequestDiscount={onRequestDiscount}
        />
      </div>
    </div>
  );
};
