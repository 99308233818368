import { useState, useMemo, useEffect } from 'react';
import * as pagePaths from '../../constants/pagePaths';

import type { AppBarProps } from '@farmersdog/corgi';
import { AppBar } from '@farmersdog/corgi';
import { useDeepLink, useScreenWidth } from '../../hooks';
import styles from './PublicHeader.module.css';

import { useHistory } from 'react-router';

import { PortalBanner } from '../PortalBanner';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import type { BannerConfig } from '../../abTesting/features';
import { TermsChangesBanner } from '../TermsChangesBanner';
import { getTermsChangesNoticeDismissed } from '../../utils/cookies/termsChangesNotice';
import {
  getMobileNavigationItems,
  getTitleNavigationItems,
} from './navigation-items';

type AppBarComponentProps = Pick<
  AppBarProps,
  'large' | 'showCTA' | 'hasContentAbove'
> & {
  isHome: boolean;
};

const AppBarComponent = (props: AppBarComponentProps) => {
  const deepLink = useDeepLink();
  const { showImprovedLoggedOutPage } = usePublicPageFeatures();
  const url = useMemo(
    () =>
      `${pagePaths.PATH_LOGIN}?${deepLink.redirectTo}${deepLink.hash ?? ''}`,
    [deepLink.hash, deepLink.redirectTo]
  );
  // We don't want to show the CTA in every public page, only in the home page.
  const links = useMemo(
    () =>
      !props.showCTA
        ? {
            ...getTitleNavigationItems({
              loginUrl: url,
              showImprovedVersion: showImprovedLoggedOutPage,
            }),
            callToAction: undefined,
          }
        : !props.large
          ? getMobileNavigationItems({
              loginUrl: url,
            })
          : getTitleNavigationItems({
              loginUrl: url,
              showImprovedVersion: showImprovedLoggedOutPage,
            }),
    [props.large, props.showCTA, showImprovedLoggedOutPage, url]
  );

  return <AppBar {...links} {...props} />;
};

export type PublicHeaderProps = Pick<AppBarComponentProps, 'showCTA'>;

export function PublicHeader({ showCTA }: PublicHeaderProps) {
  const { isLarge } = useDelayScreenWidth();
  const { location } = useHistory();

  const isHome = useMemo(
    () => location.pathname === pagePaths.PATH_HOME,
    [location.pathname]
  );

  const {
    showPortalBanner,
    bannerConfig,
    showTermsChangesBanner,
    showImprovedLoggedOutPage,
  } = usePublicPageFeatures();

  return (
    <Nav
      isHome={isHome}
      showCTA={showCTA}
      isLarge={showImprovedLoggedOutPage || isLarge}
      showPortalBanner={showPortalBanner}
      bannerConfig={bannerConfig}
      showTermsChangesBanner={showTermsChangesBanner}
    />
  );
}

interface NavProps {
  isHome: boolean;
  showCTA: React.RefObject<HTMLElement> | undefined;
  isLarge: boolean | undefined;
  showPortalBanner?: boolean;
  bannerConfig?: BannerConfig | null;
  showTermsChangesBanner: boolean | undefined;
}

const Nav = ({
  isHome,
  showCTA,
  isLarge,
  showPortalBanner,
  bannerConfig,
  showTermsChangesBanner,
}: NavProps) => {
  const termsChangesNoticeDismissed =
    getTermsChangesNoticeDismissed() === 'true';
  const [dismissTermsChangeBanner, setDismissTermsChangeBanner] = useState(
    termsChangesNoticeDismissed
  );

  if (!isHome) {
    return (
      <span aria-label="top-navigation" className={styles.appBarWrapper}>
        <AppBarComponent isHome={isHome} showCTA={showCTA} large={isLarge} />
      </span>
    );
  }
  if (showTermsChangesBanner) {
    return (
      <>
        <TermsChangesBanner
          dismissed={dismissTermsChangeBanner}
          dismissNotice={() => setDismissTermsChangeBanner(true)}
        />
        <AppBarComponent
          isHome={isHome}
          showCTA={showCTA}
          large={isLarge}
          hasContentAbove={!dismissTermsChangeBanner}
        />
      </>
    );
  }
  if (showPortalBanner) {
    return (
      <>
        <PortalBanner
          link={bannerConfig?.link ?? ''}
          buttonVerbiage={bannerConfig?.buttonVerbiage ?? ''}
          textVerbiage={bannerConfig?.textVerbiage ?? ''}
        />
        <AppBarComponent
          isHome={isHome}
          showCTA={showCTA}
          large={isLarge}
          hasContentAbove
        />
      </>
    );
  }
  return <AppBarComponent isHome={isHome} showCTA={showCTA} large={isLarge} />;
};

/**
 * The legacy AppBar component conditionally renders EITHER the mobile OR desktop nav to the DOM based on the `large` prop.
 * This is problematic with React 18's hydrateRoot method since the server-rendered html is not necessarily the same as that of client for hydration.
 * As a workaround before migrating to use the new AppNav component (ideally together with the Layout component) which does not have the same issue, we can set a default value to be passed as the `large` prop when the component first renders. This way, the same value will be used during server build time and client hydration time, making the html consistent.
 */

function useDelayScreenWidth() {
  const { isLargeScreen, isExtraLargeScreen } = useScreenWidth();

  // TODO to be removed when AppBar/Layout is used - https://app.shortcut.com/farmersdog/story/119282/replace-legacy-appbar-with-appnav
  const [isLarge, setIsLarge] = useState(false);
  useEffect(() => {
    setIsLarge(isLargeScreen || isExtraLargeScreen);
  }, [isLargeScreen, isExtraLargeScreen]);

  return {
    isLarge,
  };
}
