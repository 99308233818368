import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Resources } from './Resources';
import { TestimonialsLegacy } from './TestimonialsLegacy';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import { AccountPage } from '../../components/AccountPage';
import { VetDiscount } from './VetDiscount';
import styles from './AccountLegacy.module.css';
import { AttestationPrompt } from './AttestationPrompt';
import type { ResponsiveProps } from '../../constants';
import { ReferralHandout } from './ReferralHandout';

export const AccountResponsive = ({ isMobile }: ResponsiveProps) => {
  const { data, loading } = useVetDetails();

  const vetData = data?.vetDetails?.vet;

  const firstName = vetData?.firstName || '';
  const discountRequests = vetData?.discountRequests || [];
  const hasDiscountRequests = discountRequests.length > 0;

  if (loading) {
    return null;
  }

  if (vetData && !vetData.attestedAtSignup) {
    return <AttestationPrompt vetDetails={data.vetDetails} />;
  }

  return (
    <AccountPage>
      <Grid>
        <GridItem>
          <Text
            as="p"
            variant={isMobile ? 'article-16' : 'article-20'}
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="xs"
          >
            Welcome{firstName ? `, ${firstName}!` : '!'}
          </Text>
          <Text
            as="h2"
            variant={isMobile ? 'heading-28' : 'heading-40'}
            bold
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="none"
          >
            The Farmer’s Dog Vet Team Portal
          </Text>
        </GridItem>
        <Grid className={styles.container} rowGap="md">
          {!hasDiscountRequests && (
            <GridItem>
              <VetDiscount hasDiscountRequests={hasDiscountRequests} />
            </GridItem>
          )}
          <GridItem>
            <ReferralHandout />
          </GridItem>
          <GridItem>
            <Resources
              shouldShowDiscountSection={discountRequests.length < 1}
            />
          </GridItem>
          <GridItem>
            <TestimonialsLegacy />
          </GridItem>
        </Grid>
      </Grid>
    </AccountPage>
  );
};

export const AccountLegacy = () => {
  return (
    <>
      <div className={styles.mobile}>
        <AccountResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <AccountResponsive isMobile={false} />
      </div>
    </>
  );
};
