import type { FC, ReactElement } from 'react';

import { useAuthContext } from '../../context';

import type { RouteProps } from 'react-router-dom';
import { Route, Redirect, useLocation } from 'react-router-dom';

import * as pagePaths from '../../constants/pagePaths';
import { AttestedAtSignupRoute } from './AttestedAtSignupRoute';
import { useDeepLink, useQueryParameters } from '../../hooks';

export interface AuthorizedRouteProps extends RouteProps {
  children: ReactElement;
}

export const AuthorizedRoute: FC<AuthorizedRouteProps> = ({
  children,
  path,
  exact,
}) => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const { pathname } = useLocation();
  const deepLink = useDeepLink({ nextOverride: pathname });

  const query = useQueryParameters();
  const existingUser = query.get('existingUser');
  const redirectPathname =
    existingUser?.toLowerCase() == 'true'
      ? pagePaths.PATH_LOGIN
      : pagePaths.PATH_SIGNUP;

  return (
    <>
      {!isLoading && (
        <Route
          path={path}
          exact={exact}
          render={({ location }) =>
            isAuthenticated ? (
              <AttestedAtSignupRoute>{children}</AttestedAtSignupRoute>
            ) : (
              <Redirect
                to={{
                  pathname: redirectPathname,
                  search: deepLink.redirectTo,
                  hash: deepLink.hash,
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
