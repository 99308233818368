import { Text, Button, Grid, GridItem, Section } from '@farmersdog/corgi-x';
import { AccountPage } from '../../components/AccountPage';
import type { MemberCardProps } from '../../components/MemberCard';
import { MemberCardForOurTeam } from '../../components/MemberCard';

import { teamMembersData } from './TeamMembers';
import * as externalLinks from '../../constants/externalLinks';
import * as pagePaths from '../../constants/pagePaths';

import styles from './OurTeam.module.css';
import { LightboxId, useGlobalLightbox } from '../../hooks';
import { BioModal } from '../../components/MemberCard/components/BioModal';
import { useState } from 'react';
import DogIntimateSVG from './assets/DogIntimate.svg?react';
import classNames from 'classnames';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const OurTeam = () => {
  const { open: openBioModal } = useGlobalLightbox({
    id: LightboxId.Biography,
  });
  const [selectedMember, setSelectedMember] = useState<MemberCardProps>();

  const clickedReadMore = (id: number) => {
    const member = teamMembersData.filter(m => m.id === id)[0];
    setSelectedMember(member);
    openBioModal();
  };

  const { showPortalUXOverhaul } = usePublicPageFeatures();
  const teamCardClass = classNames(styles.cardContainer, {
    [styles.sharp]: showPortalUXOverhaul,
  });
  const openRolesCardClass = classNames(styles.openRolesCard, {
    [styles.sharp]: showPortalUXOverhaul,
  });

  return (
    <AccountPage>
      <Grid bottomSpacing="md">
        <GridItem>
          <Text
            as="h2"
            variant="heading-40"
            bold
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="none"
          >
            Meet Our Team
          </Text>
          <Text
            as="p"
            variant={showPortalUXOverhaul ? 'heading-16' : 'article-16'}
            color="charcoal-3"
            topSpacing="sm"
            bottomSpacing="none"
          >
            Our team of dedicated veterinary and nutrition-science professionals
            and passionate dog people are available to answer your questions.
          </Text>
          <Text
            as="p"
            variant={showPortalUXOverhaul ? 'heading-16' : 'article-16'}
            color="charcoal-3"
            topSpacing="sm"
            bottomSpacing="none"
          >
            Have questions for our team?{' '}
            <Button
              variant="plain-text"
              type="link"
              to={pagePaths.PATH_CONTACT_US}
              color="kale"
              underline
            >
              <Text
                variant={showPortalUXOverhaul ? 'heading-16' : 'article-16'}
              >
                Contact Us
              </Text>
            </Button>
            .
          </Text>
        </GridItem>
      </Grid>
      <Grid gap="md" vSpacing="sm">
        {teamMembersData.map(member => (
          <GridItem
            md={6}
            key={member.id}
            className={styles.memberCardContainer}
          >
            <Section as="div" variant="card" className={teamCardClass}>
              <MemberCardForOurTeam
                {...member}
                onReadMore={() => clickedReadMore(member.id)}
              />
            </Section>
          </GridItem>
        ))}
        <GridItem md={6}>
          <Section as="div" variant="card" className={openRolesCardClass}>
            <div className={styles.dogIntimateSvg}>
              <DogIntimateSVG />
            </div>
            <Text
              as="p"
              bold
              topSpacing="xs"
              bottomSpacing="md"
              variant="heading-22"
              color="white"
            >
              Interested in joining our team? We’re hiring.
            </Text>
            <Button
              variant="primary-mini"
              type="anchor"
              href={externalLinks.PATH_MAIN_SITE_CAREER}
              target="_blank"
            >
              View Open Roles
            </Button>
          </Section>
        </GridItem>
      </Grid>
      {selectedMember && <BioModal {...selectedMember} />}
    </AccountPage>
  );
};
