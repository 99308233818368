import { track } from '../track';
import { landing_page_clicked_hero_link } from './eventNames';
import type { SectionName } from '../../pages/Home/Hero/nav-items';

/**
 * Send a tracking event for when a user clicks on any link in the Hero section.
 */

export interface TrackLandingPageClickedHeroLinkArgs {
  title: SectionName;
}

export function trackLandingPageClickedHeroLink({
  title,
}: TrackLandingPageClickedHeroLinkArgs) {
  track({
    eventName: landing_page_clicked_hero_link,
    eventProps: {
      title,
    },
  });
}
