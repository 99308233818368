import { Button, Divider } from '@farmersdog/corgi-x';

import { browserOnly } from '@farmersdog/utils';
import type { MenuItem } from '../menuItems';
import { menuItems } from '../menuItems';

import styles from './FAQsMenu.module.css';

const ENTER = 13;

interface FAQsMenuProps {
  menuItemRefs: Record<string, string>;
  menuItems: MenuItem[];
}

export const FAQsMenu = ({ menuItemRefs }: FAQsMenuProps) => {
  // Function to handle key up event for accessibility purposes
  function handleKeyUp(event: React.KeyboardEvent<HTMLElement>, id: string) {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === ENTER) {
      scrollTo(id);
    }
  }

  function scrollTo(id: string) {
    const element = document.querySelector(`#${id}`);

    browserOnly(() => {
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  return (
    <ul className={styles.list}>
      {menuItems.map(({ name, label }) => (
        <li
          tabIndex={0}
          key={name}
          onKeyUp={event => handleKeyUp(event, menuItemRefs[name])}
          onClick={() => scrollTo(menuItemRefs[name])}
        >
          <Button
            variant="plain-text"
            color="kale"
            weight="bold"
            underline
            className={styles.email}
          >
            {label}
          </Button>
          <Divider width={1} color="Blueberry1" orientation="horizontal" />
        </li>
      ))}
    </ul>
  );
};
