import type { FC } from 'react';

import {
  PageTitle,
  LoginSetNewPasswordForm,
  Card,
  PublicPage,
} from '../../components';
import styles from './LoginSetNewPassword.module.css';

export const LoginSetNewPassword: FC = () => {
  const pageTitle = 'Set New Password';
  const pageSubtitle =
    'Enter the temporary code provided to you via email and choose a password (6 characters minimum). ';

  return (
    <PublicPage blueberryBackground>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <PageTitle
            title={pageTitle}
            subtitle={pageSubtitle}
            titleColor="blueberry-3"
          />
        </div>
        <div className={styles.loginFormCard}>
          <Card>
            <LoginSetNewPasswordForm />
          </Card>
        </div>
      </div>
    </PublicPage>
  );
};
