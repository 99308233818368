import { MemberCard } from '../../../components/MemberCard';
import { Grid, GridItem, Link, PageWidth, Text } from '@farmersdog/corgi-x';

import { teamMembers } from './teamMembers';
import * as pagePaths from '../../../constants/pagePaths';

import styles from './MeetOurTeamSection.module.css';
import { SectionId } from '../Hero/nav-items';
import { trackLandingPageClickedLoggingInToOurPortal } from '../../../analytics/events';

interface ResponsiveMeetOurTeamSection {
  isMobile?: boolean;
}

export function ResponsiveMeetOurTeamSection({
  isMobile,
}: ResponsiveMeetOurTeamSection) {
  return (
    <PageWidth>
      <Grid
        rowGap="xxl"
        topSpacing="xxl"
        columnGap="md"
        bottomSpacing="xxl"
        justifyContent={'center'}
      >
        <GridItem className={styles.infoContainer}>
          <Text
            variant={isMobile ? 'heading-28' : 'heading-52'}
            bold
            color="blueberry-3"
          >
            Meet Some of Our Team
          </Text>
          <Text
            variant={isMobile ? 'article-16' : 'article-20'}
            color="charcoal-3"
          >
            Our team of dedicated veterinary and nutrition-science professionals
            and passionate dog people are available to answer your questions.
            Meet some of them here, and find out more about our whole team by
            logging in to{' '}
            <Link
              to={pagePaths.PATH_OUR_TEAM}
              className={styles.signupLink}
              onClick={() => {
                trackLandingPageClickedLoggingInToOurPortal();
              }}
            >
              our portal
            </Link>
            .
          </Text>
        </GridItem>
        {teamMembers.map(({ name, education, position, alt, sources }) => (
          <GridItem lg={4} key={name} className={styles.membersContainer}>
            <MemberCard
              name={name}
              education={education}
              position={position}
              sources={sources}
              alt={alt}
            />
          </GridItem>
        ))}
      </Grid>
    </PageWidth>
  );
}

export function MeetOurTeamSection() {
  return (
    <section className={styles.section} id={SectionId.AboutOurTeam}>
      <div className={styles.mobile}>
        <ResponsiveMeetOurTeamSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveMeetOurTeamSection />
      </div>
    </section>
  );
}
