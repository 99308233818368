import type * as Apollo from '@apollo/client';

import type {
  ClinicalFormulasQuery,
  ClinicalFormulasQueryVariables,
} from '../types';
import clinicalFormulasQuery from '../queries/clinical-formulas.graphql';
import { useQuery } from '../../services/apollo';

type Options = Apollo.QueryHookOptions<
  ClinicalFormulasQuery,
  ClinicalFormulasQueryVariables
>;

export function useClinicalFormulas(options?: Options) {
  return useQuery<ClinicalFormulasQuery, ClinicalFormulasQueryVariables>(
    clinicalFormulasQuery,
    options
  );
}
