import type { FC } from 'react';
import { useState } from 'react';
import { Hero } from './Hero';
import { HealthyDogsSection } from './HealthyDogsSection/HealthyDogsSection';
import { SolutionsSection } from './SolutionsSection';
import { WhatFreshFoodIsSection } from './WhatFreshFoodIsSection';
import { WhatFreshFoodIsNotSection } from './WhatFreshFoodIsNotSection';
import { BackedByScienceSection } from './BackedByScienceSection';
import { MeetOurTeamSection } from './MeetOurTeamSection';
import { RegisterBanner } from './RegisterBanner';

import { ScienceAndSafetySection } from './ScienceAndSafetySection';
import type { SectionName } from './Hero/nav-items';
import { SectionId } from './Hero/nav-items';
import { FAQSection } from './FAQSection';
import { Button, Grid, SecondaryNavigation } from '@farmersdog/corgi-x';
import * as pagesPaths from '../../constants/pagePaths';
import { useOnScrollEnd, useTopAndBottomIntersection } from '../../hooks';
import { PublicPage, InterceptedSection } from '../../components';
import styles from './Home.module.css';
import { HomeLegacy } from './HomeLegacy';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { useHistory } from 'react-router';
import { trackLandingPageClickedStickyNavLink } from '../../analytics/events';

export const Home: FC = () => {
  const { showImprovedLoggedOutPage } = usePublicPageFeatures();
  // when the user scrolls manually or clicks any link, we want to block the intersections work.
  // otherwise there will be collisions with the navigation and the intersections
  const [blockNavigation, setBlockNavigation] = useState(true);
  const {
    location: { hash },
  } = useHistory();

  // when the scrolling action is completed, we want to unblock the intersections work
  useOnScrollEnd({
    onScrollEnd: () => {
      setBlockNavigation(false);
    },
  });

  const {
    bottomRef,
    intercepted: hideMainNavBar,
    topRef,
  } = useTopAndBottomIntersection({
    // margin of intersection for the top section (register strip) on the viewport
    topRootMargin: '-20% 0% -70% 0%',
    // margin of intersection for the bottom section (show the sticky navbar) on the viewport
    bottomRootMargin: '0% 0% -90% 0%',
  });

  if (!showImprovedLoggedOutPage) {
    return <HomeLegacy />;
  }
  return (
    <>
      <PublicPage hideAppBar={hideMainNavBar}>
        <div ref={topRef}>
          <Hero
            onLinkClick={() => {
              // It will block the events emitted by the intersection observer
              setBlockNavigation(true);
            }}
          />
          <RegisterBanner />
        </div>
        <div ref={bottomRef}>
          {hideMainNavBar && (
            <div
              className={styles.secondaryNavBar}
              data-testid="secondary-navbar"
            >
              <SecondaryNavigation
                callToActions={
                  <>
                    <Button
                      to={pagesPaths.PATH_SIGNUP}
                      variant="secondary-mini"
                      className={styles.smallerFont}
                      type="link"
                    >
                      Register for Vet Portal
                    </Button>
                  </>
                }
                onSelectItem={({ label }) => {
                  // It will block the events emitted by the intersection observer if a link is selected
                  setBlockNavigation(true);
                  trackLandingPageClickedStickyNavLink({
                    title: label as SectionName,
                  });
                }}
                items={[
                  {
                    label: 'Science & Safety',
                    to: `#${SectionId.ScienceAndSafety}`,
                  },
                  {
                    label: 'About Our Team',
                    to: `#${SectionId.AboutOurTeam}`,
                  },
                  {
                    label: 'Why Fresh',
                    to: `#${SectionId.WhyFresh}`,
                  },
                  {
                    label: 'Articles & Studies',
                    to: `#${SectionId.ArticlesAndStudies}`,
                  },
                ]}
              />
            </div>
          )}
          <HealthyDogsSection />
          <Grid>
            <InterceptedSection
              id={SectionId.ScienceAndSafety}
              activeHash={hash}
              blockIntersections={blockNavigation}
            >
              <ScienceAndSafetySection />
              <SolutionsSection />
            </InterceptedSection>
            <InterceptedSection
              id={SectionId.AboutOurTeam}
              activeHash={hash}
              blockIntersections={blockNavigation}
            >
              <MeetOurTeamSection />
            </InterceptedSection>
            <InterceptedSection
              id={SectionId.WhyFresh}
              activeHash={hash}
              blockIntersections={blockNavigation}
            >
              <WhatFreshFoodIsSection />
              <WhatFreshFoodIsNotSection />
            </InterceptedSection>
            <InterceptedSection
              id={SectionId.ArticlesAndStudies}
              activeHash={hash}
              blockIntersections={blockNavigation}
            >
              <BackedByScienceSection />
            </InterceptedSection>
          </Grid>
          <FAQSection />
        </div>
      </PublicPage>
    </>
  );
};
