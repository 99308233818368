import type { FormFieldArray } from '../../../../FormFields';
import { FieldType } from '../../../../FormFields';

export enum EnterEmailFormFields {
  email = 'email',
}

export const enterEmailFormFieldsData: FormFieldArray<EnterEmailFormFields> = [
  {
    name: EnterEmailFormFields.email,
    type: FieldType.Text,
    label: 'Email address',
    inputMode: 'email',
  },
];
