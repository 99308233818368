import { useMemo } from 'react';
import { useQueryParameters } from './useQueryParameters';
import { useHistory, useLocation } from 'react-router';

export interface PushArgs {
  defaultRedirectPath: string;
}

export interface UseDeepLinkArgs {
  nextOverride?: string;
}
/**
 * Custom hook that returns
 * The url from the deeplink. It gets it from the 'next' query parameter.
 * Usually used in buttons or links that redirect to pages that will need the deeplink after performing an task.
 * A function which redirects to the deeplink contained in the 'next' query parameter if it exists, otherwise it redirects to the defaultRedirectPath.
 * Usually is called after a login or signup is made.
 * A hash with the id of the section that needs to go after the navigation
 */

export function useDeepLink({ nextOverride }: UseDeepLinkArgs = {}) {
  const query = useQueryParameters();
  const history = useHistory();
  const { hash } = useLocation();

  const redirectTo = useMemo(() => {
    const newQuery = new URLSearchParams();
    if (nextOverride) {
      newQuery.set('next', nextOverride);
    } else {
      const next = query.get('next');
      if (next) {
        newQuery.set('next', next);
      }
    }
    const resource = query.get('resource');

    if (resource) {
      newQuery.set('resource', resource);
    }
    return newQuery.toString();
  }, [query, nextOverride]);

  const push = ({ defaultRedirectPath }: PushArgs) => {
    const next = query.get('next');
    if (next) {
      const resource = query.get('resource');

      let search;
      if (resource) {
        search = `?resource=${resource}`;
      }
      history.replace({
        pathname: next,
        hash,
        search,
      });
      return;
    }

    history.replace({
      pathname: defaultRedirectPath,
      hash,
    });
  };

  return { redirectTo, hash, push };
}
