import type { FC } from 'react';

import { PageTitle, LoginForm, PublicPage, Card } from '../../components';
import styles from './Login.module.css';

import * as pagePaths from '../../constants/pagePaths';
import { Button, Text } from '@farmersdog/corgi-x';
import { useDeepLink } from '../../hooks';
import StethoscopeSvg from '../../pages/Home/assets/stethoscope.svg?react';

export const Login: FC = () => {
  const deepLink = useDeepLink();
  const pageTitle = 'Welcome Back!';
  const pageSubtitle = 'We’re glad you’re here. Please log in to continue. ';

  return (
    <PublicPage blueberryBackground>
      <div className={styles.container}>
        <StethoscopeSvg className={styles.stethoscope} />
        <div className={styles.titleContainer}>
          <PageTitle
            title={pageTitle}
            subtitle={pageSubtitle}
            titleColor="blueberry-3"
          />
        </div>
        <Card className={styles.loginFormCard}>
          <LoginForm />
        </Card>
        <Text
          as="h3"
          variant="heading-16"
          bold
          topSpacing="md"
          bottomSpacing="md"
          color="kale-3"
        >
          Don’t have an account?
        </Text>
        <Button
          variant="secondary-mini-kale-experimental"
          type="link"
          className={styles.registerButton}
          to={{
            pathname: pagePaths.PATH_SIGNUP,
            search: deepLink.redirectTo,
            hash: deepLink.hash,
          }}
        >
          Register
        </Button>
      </div>
    </PublicPage>
  );
};
