import { arrayToOptions } from '../../../../../utils';
import { states } from '../../../../../constants';

import type { FormFieldArray } from '../../../../../components/FormFields';
import { FieldType } from '../../../../../components/FormFields';

export enum AddOrganizationFormFields {
  name = 'name',
  phone = 'phone',
  streetAddress1 = 'streetAddress1',
  streetAddress2 = 'streetAddress2',
  city = 'city',
  state = 'state',
  zip = 'zip',
}

interface generateOrganizationFieldsArgs {
  isPractice: boolean;
}

export const generateOrganizationFields = ({
  isPractice,
}: generateOrganizationFieldsArgs): FormFieldArray<AddOrganizationFormFields> => [
  {
    name: AddOrganizationFormFields.name,
    type: FieldType.Text,
    label: isPractice ? 'Practice Name' : 'Organization Name',
  },
  {
    name: AddOrganizationFormFields.phone,
    type: FieldType.Text,
    label: isPractice
      ? 'Practice Phone Number (Optional)'
      : 'Phone Number (Optional)',
    inputMode: 'tel',
  },

  {
    name: AddOrganizationFormFields.streetAddress1,
    type: FieldType.Text,
    label: 'Street Address',
  },
  {
    name: AddOrganizationFormFields.streetAddress2,
    type: FieldType.Text,
    label: 'Apt., Floor, Unit, etc (Optional)',
  },
  {
    name: AddOrganizationFormFields.city,
    type: FieldType.Text,
    label: 'City',
  },
  {
    name: AddOrganizationFormFields.state,
    type: FieldType.Select,
    label: 'State',
    options: arrayToOptions(states),
  },
  {
    name: AddOrganizationFormFields.zip,
    type: FieldType.Text,
    label: 'Zip Code',
    inputMode: 'numeric',
  },
];
