/* eslint-env node */
export const validEnvironments = [
  'development',
  'test',
  'staging',
  'production',
] as const;

export enum RenderMode {
  client = 'client',
  hydrate = 'hydrate',
}

export default {
  env: {
    doc: 'The build environment.',
    format: validEnvironments,
    default: 'development',
    env: 'NODE_ENV',
  },
  server: {
    host: {
      doc: 'The IP address to bind.',
      format: String,
      default: '::',
      env: 'HOST',
    },
    port: {
      doc: 'The port to bind.',
      format: 'port',
      default: 3003,
      env: 'PORT',
    },
    logLevel: {
      doc: 'Log severity level (server only).',
      format: String,
      default: 'info',
      env: 'LOG_LEVEL',
    },
  },
  app: {
    api: {
      url: {
        doc: 'The protocol for the url.',
        format: String,
        default: 'http://[::]:5002',
        env: 'APP_API_URL',
      },
      graphQLRoute: {
        doc: 'The graphql route on the api.',
        format: String,
        default: '/graphql',
        env: 'API_GRAPHQL_ROUTE',
      },
    },
    cognito: {
      clientId: {
        doc: 'AWS Cognito application client ID',
        format: String,
        default: '7stv82pfsrtosebm4jjqpfm3mi',
        env: 'AWS_COGNITO_CLIENT_ID',
      },
      userPoolId: {
        doc: 'AWS Cognito user pool ID',
        format: String,
        default: 'us-east-1_9rLMHNf67',
        env: 'AWS_COGNITO_USER_POOL_ID',
      },
      region: {
        doc: 'AWS Cognito region',
        format: String,
        default: 'us-east-1',
        env: 'AWS_COGNITO_REGION',
      },
      logLevel: {
        doc: 'Cognito console log level',
        format: ['DEBUG', 'ERROR', 'INFO', 'WARN', 'VERBOSE'],
        default: 'INFO',
        env: 'AWS_COGNITO_LOG_LEVEL',
      },
    },
    ga4: {
      enabled: {
        doc: 'Whether to enable the site-based GA4 integration',
        format: Boolean,
        default: false,
        env: 'GA4_ENABLED',
      },
      vetPortalMeasurementId: {
        doc: 'The destination measurement ID for analytics data for vet portal',
        format: String,
        default: '',
        env: 'GA4_VET_PORTAL_MEASUREMENT_ID',
      },
      websiteMeasurementId: {
        doc: 'The destination measurement ID for analytics data for website',
        format: String,
        default: '',
        env: 'GA4_WEBSITE_MEASUREMENT_ID',
      },
      debugView: {
        doc: 'Whether to enable DebugView for this environment',
        format: Boolean,
        default: false,
        env: 'GA4_DEBUG_VIEW',
      },
    },
    splitio: {
      authorizationKey: {
        doc: 'Split IO authorization key',
        format: String,
        default: 'localhost',
        env: 'SPLIT_AUTH_KEY',
      },
      debug: {
        doc: 'Split IO debug logging',
        format: Boolean,
        default: false,
        env: 'SPLIT_DEBUG',
      },
      override: {
        doc: 'Replace treatment result with query string value',
        format: Boolean,
        default: false,
        env: 'SPLIT_OVERRIDE',
      },
    },
    website: {
      url: {
        doc: 'The protocol/domain/port of our main website',
        format: String,
        default: 'http://localhost:3000',
        env: 'APP_WEBSITE_URL',
      },
    },
    portal: {
      url: {
        doc: 'The protocol/domain/port of our vet website',
        format: String,
        default: 'http://localhost:3003',
        env: 'APP_PORTAL_URL',
      },
    },
    build: {
      doc: 'The build number. This is set below in this file using the BUILD_NUM file created from the build script.',
      format: String,
      default: '',
      env: 'BUILD_NUM',
    },
    version: {
      doc: 'The app version number, usually from an annotated tag release. E.g. v2019-10-22. This is set below like the `BUILD_NUM` config.',
      format: String,
      default: '',
      env: 'APP_VERSION',
    },
    log: {
      level: {
        doc: 'log level',
        format: String,
        default: 'debug',
        env: 'LOG_LEVEL',
      },
    },
    segment: {
      enabled: {
        doc: 'Enable Segment',
        format: Boolean,
        default: true,
        env: 'SEGMENT_ENABLED',
      },
      key: {
        doc: 'segment.io write key. Segment keys are set in each environment',
        format: String,
        default: '',
        env: 'SEGMENT_KEY',
      },
    },
    clientAnalytics: {
      doc: 'Whether to enable client side analytic for local testing purposes',
      format: Boolean,
      default: false,
      env: 'CLIENT_ANALYTICS_ENABLED',
    },
    renderMode: {
      doc: 'How should the app be rendered',
      format: Object.values(RenderMode),
      default: RenderMode.client,
      env: 'APP_RENDER_MODEL',
    },
    debugMode: {
      doc: 'Enable debug mode for things that are hard to debug locally',
      format: Boolean,
      default: false,
      env: 'APP_DEBUG_MODE',
    },
    datadogRum: {
      enabled: {
        doc: 'Enable Datadog Real User Monitoring (RUM)',
        format: Boolean,
        default: false,
        env: 'DATADOG_RUM_ENABLED',
      },
      applicationId: {
        doc: 'DataDog RUM application ID',
        format: String,
        default: 'f0ec990a-295f-45f1-8082-88190d26dcf1',
        env: 'DATADOG_RUM_APPLICATION_ID',
      },
      clientToken: {
        doc: 'DataDog RUM client token',
        format: String,
        default: 'pub8c93daeff451165fb27b925e7f9891c8',
        env: 'DATADOG_RUM_CLIENT_TOKEN',
      },
    },
  },
};
