import {
  Accordion,
  AccordionPanel,
  Grid,
  GridItem,
  Link,
  Text,
} from '@farmersdog/corgi-x';
import { Card, Picture } from '../../../../components';
import { pdfSectionData } from './pdfSectionData';
import styles from '../ResourcesContent.module.css';
import {
  trackDownloadableInfo,
  trackResourcesPageToggleAccordion,
} from '../../../../analytics/events';

export const PdfSection = () => {
  return (
    <Card className={styles.accordionCard}>
      {pdfSectionData.map(section => (
        <Accordion key={section.sectionHeading} className={styles.accordion}>
          <AccordionPanel
            id={section.sectionHeading}
            heading={section.sectionHeading}
            expanded={section.defaultExpanded}
            onToggle={open =>
              trackResourcesPageToggleAccordion({
                action: open ? 'opened' : 'closed',
                title: section.sectionHeading,
              })
            }
          >
            {section.sectionDescription && (
              <Text
                as="p"
                variant="article-16"
                topSpacing="none"
                bottomSpacing="md"
                color="charcoal-3"
              >
                {section.sectionDescription}
              </Text>
            )}

            {section.pdfs.map(pdf => (
              <Grid key={pdf.title} bottomSpacing={'md'}>
                <GridItem sm={4} md={2}>
                  <Picture
                    alt={pdf.title}
                    sources={pdf.thumbnailSources}
                    width="72"
                    height="94"
                  />
                </GridItem>
                <GridItem sm={8} md={10}>
                  <Text variant="heading-16" bold color="charcoal-3">
                    {pdf.title}
                  </Text>

                  <Text
                    variant="article-12"
                    color="charcoal-3"
                    as="p"
                    topSpacing="xxs"
                    bottomSpacing="sm"
                  >
                    {pdf.description}
                  </Text>

                  <Link
                    target="_blank"
                    aria-label={`View ${pdf.title} PDF`}
                    rel="noopener noreferrer"
                    href={pdf.link}
                    className={styles.link}
                    onClick={() => trackDownloadableInfo({ title: pdf.title })}
                  >
                    View PDF
                  </Link>
                </GridItem>
              </Grid>
            ))}
          </AccordionPanel>
        </Accordion>
      ))}
    </Card>
  );
};
