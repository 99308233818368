import { AccountPage } from '../../components/AccountPage';
import styles from './EventsAndRecordings.module.css';
import { Grid, Text } from '@farmersdog/corgi-x';
import { Events, EventType } from './Events';
import { OnDemandVideos } from './OnDemandVideos';
import {
  getRequestedEvents,
  getUpcomingEvents,
  getUpcomingEventsLegacy,
  pastEvents,
} from '../../data/eventsAndRecordingsData';
import Doggie from './assets/Doggie.svg?react';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const EventsAndRecordings = () => {
  const upcomingEventsLegacy = getUpcomingEventsLegacy();
  const upcomingEvents = getUpcomingEvents();
  const requestedEvents = getRequestedEvents();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const { isMobile } = useScreenSizeContext();

  return (
    <AccountPage>
      <Text
        as="h2"
        variant={isMobile ? 'heading-28' : 'heading-40'}
        bold
        color="blueberry-3"
        topSpacing="none"
        bottomSpacing={isMobile ? 'md' : 'lg'}
        className={styles.title}
      >
        Events & Recordings
      </Text>
      {!showPortalUXOverhaul && (
        <Grid className={styles.svgContainer}>
          <Doggie className={styles.doggieSvg} aria-label="Doggie Icon" />
        </Grid>
      )}
      <Grid rowGap={showPortalUXOverhaul ? 'xl' : 'md'} flexDirection="column">
        {showPortalUXOverhaul && (
          <>
            <Events
              isMobile={isMobile}
              eventType={EventType.UpcomingEvents}
              events={upcomingEvents}
              showPortalUXOverhaul={showPortalUXOverhaul}
            />

            <Events
              isMobile={isMobile}
              eventType={EventType.RequestedEvents}
              events={requestedEvents}
              showPortalUXOverhaul={showPortalUXOverhaul}
            />
          </>
        )}
        {!showPortalUXOverhaul && (
          <Events
            isMobile={isMobile}
            eventType={EventType.UpcomingEvents}
            events={upcomingEventsLegacy}
            showPortalUXOverhaul={showPortalUXOverhaul}
          />
        )}
        <OnDemandVideos
          isMobile={isMobile}
          showPortalUXOverhaul={showPortalUXOverhaul}
        />
        <Events
          isMobile={isMobile}
          eventType={EventType.PastEvents}
          events={pastEvents}
          showPortalUXOverhaul={showPortalUXOverhaul}
        />
      </Grid>
    </AccountPage>
  );
};
