import { useDeepLink } from '../../hooks';
import { useAuthContext } from '../../context';
import type { ReactElement } from 'react';
import type { RouteProps } from 'react-router';
import { Route, useLocation } from 'react-router';
import * as pagePaths from '../../constants/pagePaths';

export interface PublicRouteProps extends RouteProps {
  children: ReactElement;
}

export const PublicRoute = ({ children, path, exact }: PublicRouteProps) => {
  const { isAuthenticated } = useAuthContext();
  const { pathname } = useLocation();
  const deepLink = useDeepLink();

  const isSignUpOrSignInPage =
    pathname === pagePaths.PATH_SIGNUP || pagePaths.PATH_LOGIN === pathname;

  if (isSignUpOrSignInPage && isAuthenticated) {
    deepLink.push({ defaultRedirectPath: pagePaths.PATH_DASHBOARD });
  }

  return <Route path={path} exact={exact} render={() => children} />;
};
