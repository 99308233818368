import type { ReactNode } from 'react';

import { SplitProvider } from '@farmersdog/ab-testing';
import type { SplitImpressionListener } from '@farmersdog/ab-testing/src/utils';
import { anonymousId } from '@farmersdog/lead-browser-storage';

import config from '../config';

import { anonymousFeatures, userFeatures } from './features';
import { logImpression } from './logImpression';
import { splitConfig } from './splitConfig';

interface ContentSplitProviderProps {
  children: ReactNode;
  impressionListener?: SplitImpressionListener;
  userId?: string;
  anonymousId?: string;
}

export function VetPortalSplitProvider(props: ContentSplitProviderProps) {
  const tfdAnonymousId = anonymousId.get();
  return (
    <SplitProvider<object, object, Record<string, unknown>>
      userFeatures={userFeatures}
      anonymousFeatures={anonymousFeatures}
      splitConfig={splitConfig}
      anonymousId={tfdAnonymousId}
      userId={props.userId}
      allowOverride={config.get('app.splitio.override')}
      trackImpression={logImpression}
    >
      {props.children}
    </SplitProvider>
  );
}
