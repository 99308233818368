import { Switch } from 'react-router';
import { AuthorizedRoute } from './AuthorizedRoute';

import { usePageTracker } from '../../analytics/hooks';

import { refreshSession } from '@farmersdog/sessions';
import { useUserEngagementHandler } from '@farmersdog/utils';

import { routes } from '../../constants/routes';
import { Page } from '../Page';
import { useMountGa4PageView } from '../../vendors/ga4';
import { ScreenSizeContextProvider } from '../../context/ScreenSizeContext';

// eslint-disable-next-line no-restricted-imports
import { useScreenSize } from '../../hooks/useScreenSize';
import { PublicRoute } from './PublicRoute';

export const Router = () => {
  useUserEngagementHandler({ onUserEngagement: refreshSession });
  usePageTracker();

  useMountGa4PageView();

  const screenSize = useScreenSize();
  return (
    <Switch>
      {Object.values(routes).map(
        ({
          path,
          component: Component,
          isPrivate,
          exact,
          title,
          description,
        }) =>
          isPrivate ? (
            <AuthorizedRoute key={path} path={path} exact={exact}>
              <ScreenSizeContextProvider screenSize={screenSize}>
                <Page title={title} description={description}>
                  <Component />
                </Page>
              </ScreenSizeContextProvider>
            </AuthorizedRoute>
          ) : (
            <PublicRoute key={path} path={path} exact={exact}>
              <Page title={title} description={description}>
                <Component />
              </Page>
            </PublicRoute>
          )
      )}
    </Switch>
  );
};
