import { track } from '../track';
import { seen_pdf_preview } from './eventNames';

/**
 * Send a tracking event for when a user has seen the rendered pdf preview.
 */

export interface TrackSeenPdfPreviewArgs {
  title: string;
}

export function trackSeenPdfPreview({ title }: TrackSeenPdfPreviewArgs) {
  track({
    eventName: seen_pdf_preview,
    eventProps: {
      title,
    },
  });
}
