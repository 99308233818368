import { Button, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import DogSVG from '../assets/vetDiscountDog.svg?react';
import * as pagePaths from '../../../constants';

import { EligibleModal } from './EligibleModal';
import { LightboxId, useGlobalLightbox } from '../../../hooks';

import styles from './VetDiscount.module.css';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';
import {
  trackClickedActivateDiscount,
  trackClickedDiscountEligibility,
} from '../../../analytics/events';

export interface VetDiscountProps {
  hasDiscountRequests: boolean;
}

export const VetDiscount = ({ hasDiscountRequests }: VetDiscountProps) => {
  const { open } = useGlobalLightbox({
    id: LightboxId.WhoIsEligible,
  });

  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const handleEligibleClick = () => {
    open();
    trackClickedDiscountEligibility();
  };

  if (hasDiscountRequests) return null;

  return (
    <>
      <Card>
        <div className={styles.container}>
          <DogSVG className={styles.desktopDog} />
          <div className={styles.texts}>
            <Text
              bold
              as="h2"
              topSpacing="none"
              bottomSpacing="xs"
              color="blueberry-3"
              variant="heading-28"
            >
              Veterinary Discount
            </Text>
            <Text
              as="p"
              color="kale-3"
              topSpacing="none"
              bottomSpacing="md"
              variant="article-16"
            >
              You can receive a <strong>free trial box</strong> and ongoing{' '}
              <strong>50% discount</strong> for your personal subscription to
              The Farmer’s Dog!
            </Text>
            <div className={styles.bottom}>
              <div className={styles.buttons}>
                <Button
                  onClick={handleEligibleClick}
                  className={styles.eligible}
                  variant="plain-text"
                  color={showPortalUXOverhaul ? 'kale' : 'carrot'}
                >
                  See Who’s Eligible
                </Button>
                <Button
                  variant={
                    showPortalUXOverhaul
                      ? 'primary-mini-kale-experimental'
                      : 'primary-mini'
                  }
                  className={styles.loginButton}
                  type="link"
                  to={pagePaths.PATH_TRIAL_DISCOUNTS}
                  onClick={trackClickedActivateDiscount}
                >
                  Activate Now
                </Button>
              </div>
              <DogSVG className={styles.mobileDog} />
            </div>
          </div>
        </div>
      </Card>
      <EligibleModal />
    </>
  );
};
