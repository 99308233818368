import type * as Apollo from '@apollo/client';
import { useQuery } from '../../services/apollo';
import vetDetailsQuery from '../queries/vet-details.graphql';
import type {
  VetDetailsQuery as VetDetailsQueryType,
  VetDetailsQueryVariables,
} from '../types';

type Options = Apollo.QueryHookOptions<
  VetDetailsQueryType,
  VetDetailsQueryVariables
>;

export function useVetDetails(options?: Options) {
  return useQuery<VetDetailsQueryType, VetDetailsQueryVariables>(
    vetDetailsQuery,
    options
  );
}
