import { Callout, Text, Link } from '@farmersdog/corgi-x';
import { CheckCircle } from '@farmersdog/corgi-x/icons';
import * as pagePaths from '../../../constants/pagePaths';

export function DiscountRequested() {
  return (
    <Callout variant="positive" icon={<CheckCircle />}>
      <Text
        as="p"
        variant="heading-16"
        color="kale-3"
        topSpacing="none"
        bottomSpacing="none"
      >
        <strong>You’ve successfully requested your veterinary discount.</strong>{' '}
        If the discount isn’t applied to your next order, please{' '}
        <Link to={pagePaths.PATH_CONTACT_US}>
          <Text color="kale-3" underline>
            contact us
          </Text>
        </Link>
        .
      </Text>
    </Callout>
  );
}
