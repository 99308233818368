import type { FormFieldArray } from '../../FormFields';
import { FieldType } from '../../FormFields';

export enum ResetPasswordFormFields {
  code = 'code',
  email = 'email',
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export const resetPasswordFormFieldsData: FormFieldArray<ResetPasswordFormFields> =
  [
    {
      name: ResetPasswordFormFields.code,
      type: FieldType.Text,
      label: 'Enter code',
    },
    {
      name: ResetPasswordFormFields.email,
      type: FieldType.Text,
      label: 'Enter email address',
      inputMode: 'email',
    },
    {
      name: ResetPasswordFormFields.password,
      type: FieldType.Password,
      label: 'Choose a password',
    },
    {
      name: ResetPasswordFormFields.confirmPassword,
      type: FieldType.Password,
      label: 'Confirm password',
    },
  ];
