import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Card, Picture } from '../../../../components';
import type { ResponsiveProps } from '../../../../constants';
import styles from './ResourcePreview.module.css';
import {
  trackDownloadableInfo,
  trackSeenPdfPreview,
} from '../../../../analytics/events';

import { useRequestedResourcePreview } from '../../../../hooks/useRequestedResourcePreview';
import { useEffect } from 'react';

export const ResourcePreview = ({ isMobile }: ResponsiveProps) => {
  const resource = useRequestedResourcePreview();

  useEffect(() => {
    if (resource) {
      trackSeenPdfPreview({ title: resource.title });
    }
  }, [resource]);

  if (!resource) {
    return null;
  }

  return (
    <Card className={styles.chickpeaBackground}>
      <Grid
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        gap={isMobile ? 'md' : 'none'}
      >
        <GridItem md={isMobile ? 12 : 2}>
          <Picture
            alt={resource.title}
            sources={resource.thumbnailSources}
            className={styles.thumbnail}
          />
        </GridItem>
        <GridItem md={isMobile ? 12 : 10} className={styles.textContainer}>
          <Grid>
            <GridItem>
              <Text color="blueberry-3" variant="heading-22" bold>
                {resource.title}
              </Text>
            </GridItem>

            <GridItem>
              <Text
                color="charcoal-3"
                variant="article-16"
                as="div"
                topSpacing="sm"
                bottomSpacing="md"
              >
                {resource.description}
              </Text>
            </GridItem>

            <GridItem>
              <Button
                variant="secondary-mini-kale-experimental"
                type="anchor"
                href={resource.link}
                target="_blank"
                onClick={() => trackDownloadableInfo({ title: resource.title })}
              >
                View PDF
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Card>
  );
};
