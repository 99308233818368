import { Card } from '../../../../components';

import {
  Accordion,
  AccordionPanel,
  Grid,
  GridItem,
  Text,
  Link,
} from '@farmersdog/corgi-x';
import styles from '../ResourcesContent.module.css';
import { trackDownloadableInfo } from '../../../../analytics/events';
import { expertArticleSectionData } from './expertArticleSectionData';

export const ExportArticleSection = () => {
  return (
    <Card className={styles.accordionCard}>
      {expertArticleSectionData.map(section => (
        <Accordion key={section.sectionHeading} className={styles.accordion}>
          <AccordionPanel
            id={section.sectionHeading}
            heading={section.sectionHeading}
            expanded={section.defaultExpanded}
          >
            {section.articles.map(article => (
              <Grid key={article.title} bottomSpacing={'md'}>
                <GridItem sm={12}>
                  <div key={article.title}>
                    <Text
                      variant="heading-16"
                      bold
                      color="charcoal-3"
                      as="div"
                      bottomSpacing="sm"
                    >
                      {article.title}
                    </Text>

                    <Link
                      target="_blank"
                      aria-label={`View ${article.title} Article`}
                      rel="noopener noreferrer"
                      href={article.link}
                      className={styles.link}
                      onClick={() =>
                        trackDownloadableInfo({ title: article.title })
                      }
                    >
                      View Article
                    </Link>
                  </div>
                </GridItem>
              </Grid>
            ))}
          </AccordionPanel>
        </Accordion>
      ))}
    </Card>
  );
};
