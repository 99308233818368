import type { MemberCardProps } from '../../../components/MemberCard';

import ryanWebp from '../assets/ryan.webp';
import ryanJp2 from '../assets/ryan.jp2';
import ryanPng from '../assets/ryan.png';

import josephWebp from '../assets/joseph.webp';
import josephJp2 from '../assets/joseph.jp2';
import josephPng from '../assets/joseph.png';

import brittanyWebp from '../assets/brittany.webp';
import brittanyJp2 from '../assets/brittany.jp2';
import brittanyPng from '../assets/brittany.png';

import rainWebp from '../assets/rain.webp';
import rainJp2 from '../assets/rain.jp2';
import rainPng from '../assets/rain.png';

import brandonWebp from '../assets/brandon.webp';
import brandonJp2 from '../assets/brandon.jp2';
import brandonPng from '../assets/brandon.png';

import raeWebp from '../assets/rae.webp';
import raeJp2 from '../assets/rae.jp2';
import raePng from '../assets/rae.png';

import maggieWebp from '../assets/maggie.webp';
import maggieJp2 from '../assets/maggie.jp2';
import maggiePng from '../assets/maggie.png';

import nathanWebp from '../assets/nathan.webp';
import nathanJp2 from '../assets/nathan.jp2';
import nathanPng from '../assets/nathan.png';

import arielleWebp from '../assets/arielle.webp';
import arielleJp2 from '../assets/arielle.jp2';
import ariellePng from '../assets/arielle.png';

import leahWebp from '../assets/leah.webp';
import leahJp2 from '../assets/leah.jp2';
import leahPng from '../assets/leah.png';

import caitlinWebp from '../assets/caitlin.webp';
import caitlinJp2 from '../assets/caitlin.jp2';
import caitlinPng from '../assets/caitlin.png';

const ryanProfileSources = [ryanWebp, ryanJp2, ryanPng];
const josephProfileSources = [josephWebp, josephJp2, josephPng];
const brittanyProfileSources = [brittanyWebp, brittanyJp2, brittanyPng];
const rainProfileSources = [rainWebp, rainJp2, rainPng];
const brandonProfileSources = [brandonWebp, brandonJp2, brandonPng];
const raeProfileSources = [raeWebp, raeJp2, raePng];
const maggieProfileSources = [maggieWebp, maggieJp2, maggiePng];
const nathanProfileSources = [nathanWebp, nathanJp2, nathanPng];
const arielleProfileSources = [arielleWebp, arielleJp2, ariellePng];
const leahProfileSources = [leahWebp, leahJp2, leahPng];
const caitlinProfileSources = [caitlinWebp, caitlinJp2, caitlinPng];

export const brandonMemberProfile = {
  id: 1,
  name: 'Brandon Stapleton',
  education: 'DVM, BS',
  position: 'Veterinary Team',
  sources: brandonProfileSources,
  alt: 'Brandon profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Brandon Stapleton graduated from Auburn CVM and practiced in an AAHA-accredited hospital in Indiana for about ten years, where he served as Medical Director and Managing Doctor. From there, he moved into the animal health and nutrition industry with Hill’s, spending most of his tenure there building various teams including those focused on education and digital services to vets. He then joined The Farmer’s Dog to lead their veterinary and professional health organizations.',
  howTheyHelpDogsTextLines: 5,
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'I have thoroughly enjoyed my time at The Farmer’s Dog for three major reasons: 1) Our unyielding focus on dog health 2) Our science-first, science-always approach to any and everything that we do 3) A positive culture that opens the door for great ideas and input to come from anywhere, and never settles for “how we’ve always done it”.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'Tori, a 15 year-old DSH, who would benefit from a portioned fresh food option for cats!',
  text: "Dr. Brandon Stapleton graduated from Auburn CVM and practiced in an AAHA-accredited hospital in Indiana for about ten years, where he served as Medical Director and Managing Doctor. From there, he moved into the animal health and nutrition industry with Hill's, spending most of his tenure there building various teams including those focused on education and digital services to vets. He then joined The Farmer's Dog to lead their veterinary and professional health organizations.",
};

export const ryanMemberProfile = {
  id: 2,
  name: 'Ryan Yamka',
  education: 'PhD, MS, FACN, PAS, Dipl ACAS',
  position: (
    <>
      Research & Formulation Team, <br />
      Board-Certified Animal Nutritionist®
    </>
  ),
  sources: ryanProfileSources,
  alt: 'Ryan profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Ryan Yamka has over two decades of experience as a board-certified companion animal nutritionist in the pet food industry. He earned his bachelor’s degrees in animal science and biology from Virginia Polytechnic Institute and State University, followed by a masters degree and doctorate in animal science from UKY and an MBA from Baker University. He started his career at Hill’s Pet Nutrition in research and product development and has since developed and launched 250+ nutrition products. He has dedicated his career to enhancing the health and well-being of dogs through scientifically formulated nutrition. In addition to his full time role at The Farmer’s Dog, Dr. Yamka also serves as an advisory board member for Bond Pet Foods. He has published numerous manuscripts, abstracts and book chapters (including in Small Animal Clinical Nutrition, Edition 5), and has 50+ patents granted.',
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'I am passionate about The Farmer’s Dog for several compelling reasons: 1) Our commitment to advancing dog health through innovative nutritional solutions 2) Our rigorous adherence to scientific principles in every aspect of our work 3) A dynamic and inclusive culture that fosters creativity, collaboration, and continuous improvement.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'Lexi, Luna and Mango are all rescues.  Libby is a Boston terrier COVID dog. Her name is Liberty, and she was born July 4, 2020.',
  text: 'Dr. Yamka earned his bachelor’s degrees in animal science and biology from Virginia Polytechnic Institute and State University, followed by a masters degree and doctorate in animal science from UKY and an MBA from Baker University. While he is currently the Head of Research and Development for The Farmer’s Dog, he also serves as an advisory board member for Bond Pet Foods and as Senior Strategist, Animal Nutrition for Do Good Foods.',
};

export const rainMemberProfile = {
  id: 7,
  name: 'Rain Shields',
  position: 'Research & Development Scientist',
  sources: rainProfileSources,
  alt: 'Rain profile picture',
  howTheyHelpDogsTitle: 'How they help dogs',
  howTheyHelpDogsText:
    'Rain works in product development aiming to create the safest and healthiest food on the market - with a background in animal physiology and a passion for food they are excited to broaden our offerings!',
  whyTheyLoveTFDTitle: 'Why they love TFD',
  whyTheyLoveTFDText:
    'I love The Farmer’s Dog because I get to work with a community of intelligent, motivated folks who all have their hearts exactly where they need to be - with our dogs.',
  text: "Rain works on any and every aspect of The Farmer's Dog experience that impacts dog health. With a deep knowledge of canine nutrition and a passion for helping dogs live their best lives, Rain is standing by to help out with any nutritional questions you have about our products and services.",
};

export const josephMemberProfile = {
  id: 4,
  name: 'Joseph Wakshlag',
  education: 'DVM, PhD, DACVIM (Nutrition)',
  position: (
    <>
      Research & Formulation Team, <br />
      Board-Certified Veterinary Nutritionist®
    </>
  ),
  sources: josephProfileSources,
  alt: 'Joe profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Joe Wakshlag received his DVM from Cornell University College of Veterinary Medicine in 1998. His passion for nutrition led him to complete a residency in Veterinary Nutrition at the same time as receiving a PhD in pharmacology in 2005. In 2006 he became an Assistant Professor of Nutrition at the College of Veterinary Medicine. During this time he rose through the ranks to become a Full Professor in 2018 while also becoming a founding member of the American College of Veterinary Sports Medicine and Rehabilitation, not only serving on the Board from 2010-2014, but also helped lead the American College of Veterinary Nutrition during a similar time frame.\n\nDr. Joe has been service Chief of Service for the Nutrition Department at Cornell since 2007 and was Chief of Service of the Sports Medicine and Rehabilitation department from 2014-2018. He has been very active in benchtop and clinical studies at Cornell in the areas of canine obesity, cancer cell biology, nutraceuticals, sporting dog metabolism and nutrient requirements. He has authored over a dozen book chapters and well over 100 peer reviewed publications.',
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'As the original formulator for The Farmer’s Dog almost 10 years ago, I am continually impressed with many facets of The Farmer’s Dog. 1) our commitment to a high quality gently cooked diet with exceptional quality control measures, 2) our customer service which continues to amaze me with nutritional support for owners that the entire industry is lacking and 3) clinical study endeavors to fully understand the benefits of fresh food to back up all of the client stories The Farmer’s Dog receives regarding how feeding The Farmer’s Dog transforms dogs lives for the better.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'After running sled dogs who were part of his family for 15 years, he has downsized to a single 4 year old domestic shorthair cat named Kitty (not very original name, we know!) due to travel commitments.',
  text: "Dr. Wakshlag received his DVM in 1998 and became board certified in veterinary nutrition and canine sports medicine and rehabilitation, in 2006 and 2010 respectively. In addition to being the Veterinary Nutritionist for The Farmer's Dog, he is currently a Professor of Veterinary Nutrition at Cornell with over 15 years of research experience in canine obesity, cancer cell biology, sporting dogs and pain management.",
};

export const raeMemberProfile = {
  id: 3,
  name: 'Rae Sires',
  education: 'DVM, DACVIM (Nutrition)',
  position: (
    <>
      Research & Formulation Team, <br />
      Board-Certified Veterinary Nutritionist®
    </>
  ),
  sources: raeProfileSources,
  alt: 'Rae profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Rae Sires graduated from Iowa State University College of Veterinary Medicine and immediately moved to Dallas, Texas to complete a 1-year internship focused on small animal emergency and critical care. She completed her residency training in small animal clinical nutrition at UC Davis and became a diplomate of the American College of Veterinary Internal Medicine (Nutrition) in 2021. Dr. Sires joined a small animal general practice after her residency and has worked as a relief veterinarian in 15 different hospitals across Kansas, Missouri, and Texas. She is an active educator with the Veterinary Information Network (VIN) and also serves on the ACVIM General Exam Item Review Committee.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'The Farmer’s Dog is the first workplace that I have experienced that encourages all of its employees to challenge the status quo to ensure the entire company is always actively pursuing what is truly best for dogs!',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Tony Stark - 10 y/o DSH; Cheeseburger - 3 y/o DSH; Happy - 1 y/o Golden Retriever; Gilly - 10 y/o chinchilla',
  text: 'Dr. Rae Sires graduated from Iowa State University College of Veterinary Medicine. She completed her residency training in small animal clinical nutrition at UC Davis and became a diplomate of the American College of Veterinary Internal Medicine (Nutrition) in 2021. She joined a small animal general practice after her residency and worked as a relief veterinarian across Kansas, Missouri, and Texas. She is an active educator with the Veterinary Information Network (VIN).',
};

export const maggieMemberProfile = {
  id: 5,
  name: 'Maggie Canning',
  education: 'DVM',
  position: 'Veterinary Team',
  sources: maggieProfileSources,
  alt: 'Maggie profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Maggie Canning is a graduate of Auburn University College of Veterinary Medicine. She practiced small animal medicine in NC, TX, PA, and NJ, worked with a telemedicine startup, spoke at major veterinary conferences, and served as a field representative for Nationwide Pet Insurance. She then spent four years in IDEXX’s software organization building data and software solutions to advance patient care while continuing to practice as a relief veterinarian. She has also served on an AVMA committee and co-founded multiple veterinary organizations.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'I cherish our dedication to science - from research with universities to partnerships with industry leaders, we care a lot about evidence-based medicine. Everyone at The Farmer’s Dog is led by heart and science, ensuring that compassion and rigorous research guide every decision we make. We are constantly learning through testing hypotheses, and we never lose sight of the people and dogs we’re serving.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'My sweetest vet school pup, Cooper, a 12 year old Lab/Chesapeake Bay Retriever who taught me how special dogs are.\n\nAnd how could we have a two vet household (my husband is a boarded dermatologist), without a food allergic Golden Retriever, Chewie (who does great on our pork recipe!).',
  text: "Dr. Maggie Canning is a graduate of Auburn University College of Veterinary Medicine. She practiced small animal medicine in NC, TX, PA, and NJ, worked with a telemedicine startup, spoke at major veterinary conferences, and served as a field representative for Nationwide Pet Insurance. She then spent four years in IDEXX's software organization building data and software solutions to advance patient care while continuing to practice as a relief veterinarian. She has also served on an AVMA committee and co-founded multiple veterinary organizations.",
};

export const nathanMemberProfile = {
  id: 9,
  name: 'Nathan Jacobowitz',
  position: 'Senior Growth Manager',
  sources: nathanProfileSources,
  alt: 'Nathan profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Nathan is a graduate of San Diego State University with a degree in Business Marketing. Prior to joining The Farmer’s Dog, Nathan spent ~7 years at several high growth startups with roles in Operations and Marketing.\n\nSince joining The Farmer’s Dog in 2021, Nathan has helped drive and grow several marketing programs; all with the goal of amplifying the benefits of fresh dog food to dog owners everywhere.\n\nJoining the Vet Pros team at The Farmer’s Dog in 2024, Nathan is excited to partner directly with the our veterinary professionals on staff as well as with veterinary professionals at large.',
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'The Farmer’s Dog is a very mission-driven company, keeping dogs and dog owners at the core of every decision made.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'Lilu, 8 year old Pitbull mix, who is the pickiest of eaters; she has been known to go on hunger strikes until she is given a home-cooked meal. We used to have to rotate her food constantly for her to be interested, until we tried The Farmer’s Dog (her favorite is the beef recipe!)',
};

export const caitlinMemberProfile = {
  id: 11,
  name: 'Caitlin Hering',
  education: 'VMD',
  position: 'Senior Retention Manager, Vet Pros',
  sources: caitlinProfileSources,
  alt: 'Associate profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Caitlin Hering completed her doctorate in veterinary medicine at The University of Pennsylvania, followed by a rotating internship in Emergency, Internal Medicine, and Surgery at The Schwarzman Animal Medical Center in Manhattan. Before joining The Farmer’s Dog, Dr. Hering practiced small animal emergency, urgent care and primary care. She also helped start up a nonprofit foster-based rescue for dogs, Rain or Shine Rescue, where she remains on the Board of Directors.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'As a veterinarian, I’m uncompromising when it comes to making sure my recommendations align with my responsibility to protect the health of my patients. At The Farmer’s Dog I’m proud to be a part of a team that shares this purpose by continuously learning through research, engaging experts and leaders, and inviting feedback to ensure we’re building a product rooted in science, and doing so thoughtfully with both dog lovers and veterinary professionals in mind.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'The two dog loves of my life were both rescued island mixes from Antigua, Scooter and Simba, who both had their individual struggles that led me to dedicate my life to bettering the lives of dogs and the people who care for them.',
};

export const brittanyMemberProfile = {
  id: 6,
  name: 'Brittany Lechlinski',
  education: 'CVT',
  position: 'Senior Associate of Retention',
  sources: brittanyProfileSources,
  alt: 'Associate profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'After adopting her best friend 10 years ago, Brittany became a dedicated animal welfare advocate. She began her journey by managing a boarding facility for 3 years while working towards her veterinary technician certification through PennFoster College.\n\nBrittany then transitioned to various roles in non-profit shelters and clinics, focusing on rescue, rehabilitation, and rehoming animals, as well as addressing overpopulation at a high-volume spay-neuter clinic. Since joining The Farmer’s Dog in 2020, she has continued her work supporting and advocating for all animals and vet professionals!',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    "Working at The Farmer's Dog is incredibly fulfilling because I get to contribute to the well-being of dogs every single day. The company's commitment to creating high-quality, nutritious food aligns with my passion for animal health, and being part of a dedicated team that truly cares about pets (and the humans in their lives!) makes each day rewarding.",
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Myla is a 10 year old pug/chihuahua mix who not only controls my life, but is the unofficial vet team mascot here at The Farmer’s Dog. Rest assured, if she’s not harassing you for food, she’s silently judging you.',
  text: 'After adopting her best friend, Myla, in 2013, Brittany immersed herself in the veterinary field and has worked in various shelters and non-profit vet clinics. She joined The Farmer’s Dog during the summer of 2020, and is excited to connect with a community devoted to helping dogs live longer, healthier lives!',
};

export const arielleMemberProfile = {
  id: 10,
  name: 'Arielle Tsoran',
  position: 'Growth Manager',
  sources: arielleProfileSources,
  alt: 'Arielle profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'With a background in Growth Marketing and personalized healthcare solutions, Arielle is passionate about improving the lives of dogs everywhere using a tailored and individualistic approach.\n\nBy ensuring vet professionals have the tools and resources they need to learn about the long-term benefits of fresh food on health, we can help make the best personalized decisions for their patients.\n\nArielle is excited to be on our Veterinary Professionals Team to develop their mission of bettering the lives of dogs and giving pet owners peace of mind.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'The Farmer’s Dog’s love for dogs shines in everything we do from the sourcing and formulation of our food, to the thoughtfulness behind our customer care, to the ongoing research and education available to everyone. I am proud to be part of an organization that invests so much to our mission — bettering the lives of dogs.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Wallace, 4 year old Beagle-Bluetick Hound mix. Loves everyone. Chips, 3 year old American Shorthair. Plotting to take over the world.',
};

export const leahMemberProfile = {
  id: 8,
  name: 'Leah Olverd',
  position: 'Senior Product Manager',
  sources: leahProfileSources,
  alt: 'Leah profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Leah Olverd helps dogs by serving as a vital translator between veterinarians and customers, ensuring that complex veterinary insights are communicated in an understandable and actionable way. With her background in product development and experience running a small NYC dog training business, she empowers pet owners to make informed decisions that lead to healthier, happier dogs.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    "I love The Farmer's Dog because I get to work with some of the best vets around, who are not only amazing people but also data-driven scientists. This mix fits perfectly with my passion for dog training and product development. Plus, my own dogs absolutely love the food and my family loves their healthy (and tiny!) poops. I feel great knowing my team is making a real difference in the lives of dogs and their owners. #longlivedogs",
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Archer, 7 year old basenji with unbridled confidence, and Jet, a 3 year old chihuahua mix who is deeply committed to his mom (“he would like to live inside my skin”). You’ll spot Jet in our booth at vet conferences!',
};

export const teamMembersData: MemberCardProps[] = [
  brandonMemberProfile,
  ryanMemberProfile,
  raeMemberProfile,
  josephMemberProfile,
  maggieMemberProfile,
  caitlinMemberProfile,
  brittanyMemberProfile,
  rainMemberProfile,
  leahMemberProfile,
  nathanMemberProfile,
  arielleMemberProfile,
];
