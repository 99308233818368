import { useId } from 'react';
import { Button, Text } from '@farmersdog/corgi-x';
import styles from '../Profile.module.css';
import * as pagePaths from '../../../constants/pagePaths';
import { Card } from '../../../components';
import classNames from 'classnames';

interface PersonalDetailsProps {
  name: string;
  email: string;
  showPortalUXOverhaul?: boolean;
}
export const PersonalDetails = ({
  email,
  name,
  showPortalUXOverhaul,
}: PersonalDetailsProps) => {
  const headingId = useId();

  return (
    <Card
      className={classNames({
        [styles.tallCard]: showPortalUXOverhaul,
      })}
    >
      <Text
        as="h2"
        id={headingId}
        variant="heading-28"
        bold
        color="blueberry-3"
      >
        My Personal Details
      </Text>
      <Text variant="heading-16" color="charcoal-3">
        <strong>Name</strong>
        <br />
        {name}
      </Text>
      <Text as="p" variant="heading-16" color="charcoal-3">
        <strong>Contact Email</strong>
        <br />
        {email}
      </Text>
      <Button
        variant="plain-text"
        color={showPortalUXOverhaul ? 'kale' : 'carrot'}
        underline
        weight="normal"
        type="link"
        data-testid="update-personal"
        to={{
          pathname: pagePaths.PATH_PROFILE,
          search: 'update=personal',
        }}
      >
        Update
      </Button>
    </Card>
  );
};
