import { Text } from '@farmersdog/corgi-x';

const SUBHEADER_COPY =
  "RACE-approved continuing education is now available on-demand. After watching each video, you'll be asked to complete a quiz. Once you pass the quiz, a certificate for 1 hour of RACE-approved CE will be made available.";

export const OnDemandHeader = ({
  isMobile,
  showPortalUXOverhaul,
}: {
  isMobile?: boolean;
  showPortalUXOverhaul?: boolean;
}) => {
  return (
    <>
      <Text
        as="h3"
        variant={isMobile ? 'heading-22' : 'heading-28'}
        bold
        color="blueberry-3"
        topSpacing={'none'}
        bottomSpacing="sm"
      >
        On Demand Videos
      </Text>
      <Text
        variant={showPortalUXOverhaul ? 'heading-16' : 'article-16'}
        color="charcoal-3"
      >
        {SUBHEADER_COPY}
      </Text>
    </>
  );
};
