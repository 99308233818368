import { useHistory } from 'react-router';
import { Logger } from '@farmersdog/logger';
import { Button } from '@farmersdog/corgi-x';
import { useFormError } from '../FormError';
import { FormFields } from '../FormFields';
import { useFormikWithFocusOnError, useQueryParameters } from '../../hooks';
import { useAuthContext } from '../../context';
import {
  getErrorMessage,
  defaultErrorMessage,
} from '../../services/auth/utils/errors';
import { handleUnexpectedAuthError } from '../../services/auth/utils/handleUnexpectedAuthError';
import { isCognitoAuthenticatedUserResult } from '../../context/constants/types';
import * as pagePaths from '../../constants';

import { loginSetNewPasswordFormFields as formFields } from './loginSetNewPasswordFormFields';
import { loginSetNewPasswordFormInitialValues as initialValues } from './loginSetNewPasswordFormInitialValues';
import { loginSetNewPasswordFormValidationSchema as validationSchema } from './loginSetNewPasswordFormValidationSchema';

import styles from './LoginSetNewPasswordForm.module.css';

export function LoginSetNewPasswordForm() {
  const history = useHistory();
  const queryParam = useQueryParameters();
  const email = queryParam.get('email');

  const { FormError, clearFormErrorMessage, setFormErrorMessage } =
    useFormError();
  const { loginSetNewPassword, logout, isAuthenticated } = useAuthContext();

  const { isSubmitting, ...formik } = useFormikWithFocusOnError({
    initialValues: { ...initialValues, email: email ?? initialValues.email },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      clearFormErrorMessage();
      try {
        if (!loginSetNewPassword) {
          throw new Error('No login function provided');
        }
        if (!logout) {
          throw new Error('No logout function provided');
        }
        if (isAuthenticated) {
          await logout();
        }

        const result = await loginSetNewPassword({
          email: values.email,
          temporaryPassword: values.temporaryCode,
          newPassword: values.password,
        });

        if (isCognitoAuthenticatedUserResult(result)) {
          history.push(pagePaths.PATH_DASHBOARD);
        } else {
          throw new Error('Login action did not return expected results', {
            cause: result,
          });
        }
      } catch (error: unknown) {
        const message = getErrorMessage(error);
        setFormErrorMessage(message);

        // default error message indicates an unexpected error
        if (message === defaultErrorMessage) {
          const logger = new Logger('auth:loginSetNewPassword');
          handleUnexpectedAuthError({ error, logger });
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields formik={formik} fieldData={formFields} />
        </div>
        <div className={styles.messagesContainer}>
          <FormError />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={isSubmitting}
            variant="primary-mini-kale-experimental"
            className={styles.submitButton}
            type="submit"
          >
            Log In
          </Button>
        </div>
      </form>
    </div>
  );
}
