import type { ReactNode, Dispatch } from 'react';
import { createContext, useReducer, useMemo } from 'react';

import type { LightboxAction } from './actions';
import { lightboxReducer } from './reducers';

export interface SingleLightboxState {
  isOpen: boolean;
  suppressed?: boolean;
}

export interface GlobalLightboxState {
  lightboxes: {
    [key: string]: SingleLightboxState;
  };
}

const initialLightboxState: GlobalLightboxState = {
  lightboxes: {},
};

interface LightboxContextType {
  state: GlobalLightboxState;
  dispatch: Dispatch<LightboxAction>;
}

const LightboxContext = createContext<LightboxContextType>({
  state: initialLightboxState,
  dispatch: () => null,
});

const { Provider } = LightboxContext;

interface LightboxContextProviderProps {
  children: ReactNode;
}

function LightboxProvider({ children }: LightboxContextProviderProps) {
  const [state, dispatch] = useReducer(lightboxReducer, initialLightboxState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <Provider value={contextValue}>{children}</Provider>;
}

export { LightboxContext, LightboxProvider };
