import { track } from '../track';
import { clicked_activate_discount } from './eventNames';

/**
 * Send a tracking event for when a user clicked the activate discount button
 */

export function trackClickedActivateDiscount() {
  track({
    eventName: clicked_activate_discount,
  });
}
