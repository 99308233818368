import type { DiscountRequestState } from './discountRequestState';

export enum DiscountRequestEvent {
  Previous = 'PREVIOUS',
  Close = 'Close',
  AlreadySubscribed = 'ALREADY_SUBSCRIBED',
  NotYetSubscribed = 'NOT_YET_SUBSCRIBED',
  HowToGetDiscountContinue = 'HOW_TO_GET_DISCOUNT_CONTINUE',
  EnterEmailContinue = 'ENTER_EMAIL_CONTINUE',
  SelectPractice = 'SELECT_PRACTICE',
  AddPractice = 'ADD_PRACTICE',
  UpdatePractice = 'UPDATE_PRACTICE',
  SubmitRequest = 'SUBMIT_REQUEST',
}

export type DiscountRequestMachineEvent = {
  type: DiscountRequestEvent;
  prevStep: DiscountRequestState;
};
