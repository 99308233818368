import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { GetGa4PageViewArgs } from '@farmersdog/pixels';
import { getGa4PageViewProperties } from '@farmersdog/pixels';

import { browserOnly } from '@farmersdog/utils';
import {
  Ga4StandardEvent,
  mountGa4Event,
} from '@farmersdog/pixels/src/vendors/ga4/mountGa4Event';
import { getGa4EventConfig } from './getGa4EventConfig';

/**
 * Run an effect when the location pathname changes to mount page views with the
 * data required for a Ga4 page view event.
 */

export function useMountGa4PageView() {
  const location = useLocation();
  const { pathname, search, hash } = location;

  const origin = browserOnly(
    (window: Window) => window.location.origin,
    () => ''
  );

  useEffect(() => {
    pageView({ location: { pathname, search, hash, origin } });
  }, [pathname, hash, search, origin]);
}

const pageView = ({ location }: GetGa4PageViewArgs) => {
  const properties = getGa4PageViewProperties({ location });
  mountGa4Event({
    name: Ga4StandardEvent.PageView,
    properties,
    config: getGa4EventConfig(),
  });
};
