export const downloadable_info_click_on_download_link =
  'Downloadable Info - Clicked on download link';
export const downloadable_vet_referral_handout_on_download_link =
  'Vet Referral Handout Downloaded';

export const forgot_password_requested_reset =
  'Forgot Password - Requested Reset';
export const signup = 'Vet Portal - Signup';
export const clicked_contact_support = 'Vet Portal - Contact Support Clicked';
export const show_error_message = 'Vet Portal - Show error message';
export const profile_updated = 'Vet Portal - Profile Updated';
export const copy_button_clicked =
  'Vet Portal - Copied Instructions for Recommend to Client';
export const events_and_recordings_page_clicked_lunch_and_learn_request =
  'Vet Portal - Events and Recordings Page - Clicked Request Lunch & Learn';
export const events_and_recordings_page_clicked_university_visit_request =
  'Vet Portal - Events and Recordings Page - Clicked Request University Visit';
export const faq_review_clicked = 'Vet Portal - Review FAQs Clicked';
export const faq_question_clicked = 'Vet Portal - FAQ Question Clicked';
export const faq_activate_discount_clicked =
  'Vet Portal - FAQ Activate Discount Clicked';
export const faq_navbar_clicked = 'Vet Portal - FAQ Navbar Clicked';
export const landing_page_clicked_hero_link =
  'Vet Portal Landing Page - Clicked Jumplink:Hero';
export const landing_page_clicked_sticky_nav_link =
  'Vet Portal Landing Page - Clicked Jumplink:Sticky Nav';
export const landing_page_clicked_download_nutrition_guide =
  'Vet Portal Landing Page - Clicked Download Nutrition Guide';
export const landing_page_clicked_view_clinical_recommendations =
  'Vet Portal Landing Page - Clicked View Clinical Recommendations';
export const landing_page_clicked_logging_in_to_our_portal =
  'Vet Portal Landing Page - Clicked Login:Meet Our Team';
export const landing_page_clicked_explore_studies_and_resources =
  'Vet Portal Landing Page - Clicked Explore Studies & Resources';
export const landing_page_clicked_register_for_vet_team_portal_hero =
  'Vet Portal Landing Page - Clicked Register:Hero';
export const landing_page_clicked_register_for_vet_team_portal_have_more_questions =
  'Vet Portal Landing Page - Clicked Register:Have More Questions';
export const landing_page_clicked_register_for_vet_team_portal_sticky_navbar =
  'Vet Portal Landing Page - Clicked Register:Sticky Navbar';
export const landing_page_clicked_lunch_and_learn_request =
  'Vet Portal Landing Page - Clicked Request Lunch & Learn';
export const landing_page_clicked_university_visit_request =
  'Vet Portal Landing Page - Clicked Request University Visit';
export const contact_us_clicked_lunch_and_learn_request =
  'Vet Portal Contact Us Page - Clicked Request Lunch & Learn';
export const contact_us_clicked_university_visit_request =
  'Vet Portal Contact Us Page - Clicked Request University Visit';
export const webinars_page_clicked_share_button =
  'Vet Portal Webinar Page - Clicked Share Button';
export const webinars_page_clicked_copy_link_button =
  'Vet Portal Webinar Page - Clicked Copy Link Button';
export const terms_changes_notice_dismissed =
  'Terms Changes Notice - Dismissed';
export const dismissed_customer_account_log_in_notice =
  'Vet Portal - Dismissed Customer Account Log In Notice';
export const clicked_customer_account_log_in =
  'Vet Portal - Clicked Customer Account Log In';
export const submitted_discount_request =
  'Vet Portal - Submitted Discount Request';
export const homepage_clicked_share_your_experience_email_link =
  'Vet Portal Homepage - Clicked Share Your Experience Email Link';
export const seen_pdf_preview = 'Vet Portal - Seen PDF Preview';
export const resources_page_toggled_accordion =
  'Vet Portal Resources Page - Toggle Accordion';
export const clicked_activate_discount =
  'Vet Portal - Clicked Activate Discount';
export const clicked_discount_eligibility =
  'Vet Portal - Clicked Discount Eligibility';
