import { track } from '../track';
import { homepage_clicked_share_your_experience_email_link } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the "Email Us" link on the homepage
 */

export function trackHomepageClickedShareYourExperienceEmailLink() {
  track({
    eventName: homepage_clicked_share_your_experience_email_link,
  });
}
