import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Card, Picture } from '../../../../components';
import type { ResponsiveProps } from '../../../../constants';
import styles from './NutritionGuideCard.module.css';
import { trackDownloadableInfo } from '../../../../analytics/events';

import { nutritionGuideSectionData } from './NutritionGuideSectionData';

export const NutritionGuideCard = ({ isMobile }: ResponsiveProps) => {
  return (
    <Card>
      <Grid
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        gap={isMobile ? 'md' : 'none'}
      >
        <GridItem md={isMobile ? 12 : 2}>
          <Picture
            alt="Nutrition Guide"
            sources={nutritionGuideSectionData.thumbnailSources}
            className={styles.nutritionGuideThumbnail}
          />
        </GridItem>
        <GridItem
          md={isMobile ? 12 : 10}
          className={styles.nutritionGuideTextContainer}
        >
          <Grid>
            <GridItem>
              <Text color="blueberry-3" variant="heading-22" bold>
                {nutritionGuideSectionData.title}
              </Text>
            </GridItem>

            <GridItem>
              <Text
                color="charcoal-3"
                variant="article-16"
                as="div"
                topSpacing="sm"
                bottomSpacing="md"
              >
                Our comprehensive review of The Farmer’s Dog nutritional info,
                safety standards, and feeding instructions.
              </Text>
            </GridItem>

            <GridItem>
              <Button
                variant="primary-mini-kale-experimental"
                type="anchor"
                href={nutritionGuideSectionData.link}
                target="_blank"
                onClick={() =>
                  trackDownloadableInfo({
                    title: nutritionGuideSectionData.title,
                  })
                }
              >
                View PDF
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Card>
  );
};
