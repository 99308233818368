import { useClinicalFormulas } from '../../graphql/hooks';
import { AccountPage } from '../../components/AccountPage';

export const Formulas = () => {
  useClinicalFormulas();

  return (
    <AccountPage>
      <h1>formulas</h1>
    </AccountPage>
  );
};
