// please don't export anything from this file other than routes or the build process will stop working

export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_LOGIN_SET_NEW_PASSWORD = '/login-set-new-password';
export const PATH_SIGNUP = '/signup';
export const PATH_SIGNUP_CONFERENCE = '/signup/conference';
export const PATH_LOGOUT = '/logout';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_APP = '/app';
export const PATH_FORMULAS = `${PATH_APP}/formulas`;
export const PATH_FORMULAS_DETAILS = `${PATH_APP}/formulas/details`;
export const PATH_PROFILE = `${PATH_APP}/profile`;
export const PATH_FAQ = `${PATH_APP}/faq-for-vetpros`;
export const PATH_DASHBOARD = `${PATH_APP}/dashboard`;
export const PATH_OUR_TEAM = `${PATH_APP}/our-team`;
export const PATH_CONTACT_US = `${PATH_APP}/contact-us`;
export const PATH_DOWNLOADS = `${PATH_APP}/downloads`;
export const PATH_TRIAL_DISCOUNTS = `${PATH_APP}/trial-and-discounts`;
export const PATH_RECIPE_RECOMMENDATIONS = `${PATH_APP}/clinical-recipe-recommendations`;
export const PATH_EVENTS_AND_RECORDINGS = `${PATH_APP}/events-and-recordings`;
export const PATH_EVENT_AND_RECORDING = `${PATH_APP}/event-and-recording`;
export const PATH_NOT_FOUND = '/404';
