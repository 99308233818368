import * as yup from 'yup';
import { LoginSetNewPasswordFormFields } from './loginSetNewPasswordFormFields';
import { EMAIL_REGEXP } from '../../constants/validation';
import { noTrailingWhitespace } from '../../utils/validation/no-trailing-whitespace';
import { fieldRequiredErrorMessage } from '../../utils';

export const loginSetNewPasswordFormValidationSchema = yup.object({
  [LoginSetNewPasswordFormFields.email]: yup
    .string()
    .required(fieldRequiredErrorMessage)
    .test('trailing whitespace', 'Remove spaces', noTrailingWhitespace)
    .matches(EMAIL_REGEXP, 'Invalid email address'),
  [LoginSetNewPasswordFormFields.temporaryCode]: yup
    .string()
    .required(fieldRequiredErrorMessage),
  [LoginSetNewPasswordFormFields.password]: yup
    .string()
    .required(fieldRequiredErrorMessage),
});
