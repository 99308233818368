import type { FindPracticeQuery, FindPracticeQueryVariables } from '../types';
import findPracticeQuery from '../queries/find-practice.graphql';
import { useLazyQuery } from '../../services/apollo';

export function useFindPractice() {
  const [findPractice, { loading }] = useLazyQuery<
    FindPracticeQuery,
    FindPracticeQueryVariables
  >(findPracticeQuery, {
    fetchPolicy: 'cache-first',
  });

  return {
    findPractice,
    loading,
  };
}
