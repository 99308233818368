import { Text, Grid, GridItem, Button } from '@farmersdog/corgi-x';
import { emails } from '@farmersdog/constants';

import EnvelopeIcon from '../assets/envelope.svg?react';
import styles from './FAQsContent.module.css';
import { Card } from '../../../components';

export const EmailSection = () => {
  return (
    <div className={styles.card}>
      <Text
        variant="heading-22"
        as="h4"
        bold
        color="blueberry-3"
        topSpacing={'xl'}
      >
        Can’t find what you are looking for?
      </Text>
      <Card>
        <Grid gap="sm">
          <GridItem md={6} xs={12}>
            <Text variant="heading-22" bold color="blueberry-3">
              Reach Out
            </Text>
          </GridItem>
          <GridItem md={5} xs={12}>
            <Grid>
              <GridItem xs={2}>
                <EnvelopeIcon className={styles.contactIcon} />
              </GridItem>
              <GridItem xs={10}>
                <Button
                  href={`mailto:${emails.EMAIL_VET}`}
                  variant="plain-text"
                  type="anchor"
                  color="kale"
                  weight="bold"
                  underline
                  className={styles.email}
                >
                  {emails.EMAIL_VET}
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Card>
    </div>
  );
};
