import { contactUsFormFieldsData } from './contactUsFormFieldsData';
import { FormFields } from '../FormFields';
import { Button, Divider } from '@farmersdog/corgi-x';
import { contactUsFormValidationSchema } from './contactUsFormValidationSchema';
import { initialFormValues } from './initialFormValues';
import { useAddContact } from '../../graphql/hooks/useAddContact';
import { useFormError } from '../FormError';
import { getErrorMessage } from '../../services/auth/utils/errors';
import { useFormSuccess } from '../FormSuccess';
import styles from './ContactUsForm.module.css';
import { useFormikWithFocusOnError } from '../../hooks';
import { useVetDetails } from '../../graphql/hooks';
import { usePreviousEffect } from '@farmersdog/utils';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import classNames from 'classnames';
interface ContactUsFormProps {
  reasonSelection: string;
}
export const ContactUsForm = ({ reasonSelection }: ContactUsFormProps) => {
  const {
    FormSuccess,
    setFormSuccessMessage,
    clearFormSuccessMessage,
    formSuccessMessage: formSubmitted,
  } = useFormSuccess();

  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const [addContact] = useAddContact();
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const { data } = useVetDetails();

  let prefilledFormValues = initialFormValues;

  if (data?.vetDetails?.vet) {
    const { firstName, lastName, email } = data.vetDetails.vet;
    prefilledFormValues = {
      ...prefilledFormValues,
      firstName,
      lastName,
      email,
    };
  }

  const resetForm = () => {
    clearFormSuccessMessage();
    clearFormErrorMessage();
    formik.resetForm();
  };

  usePreviousEffect((prevSelection, newSelection) => {
    if (prevSelection !== newSelection) {
      resetForm();
    }
  }, reasonSelection);

  const formik = useFormikWithFocusOnError({
    initialValues: prefilledFormValues,
    validationSchema: contactUsFormValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      clearFormSuccessMessage();
      clearFormErrorMessage();
      try {
        await addContact({
          variables: {
            input: {
              ...values,
              contactReason: reasonSelection,
            },
          },
        });
        setFormSuccessMessage('Message sent!');
      } catch (error) {
        const message = getErrorMessage(error);
        setFormErrorMessage(message);
        return;
      }
      setSubmitting(false);
    },
  });

  // disables inputs after submission
  const fieldData = contactUsFormFieldsData.map(field => ({
    ...field,
    disabled: formSubmitted.length,
  }));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Divider
        orientation="horizontal"
        color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
        width={1}
        spacing={12}
      />
      <div className={styles.inputSection}>
        {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
        <FormFields fieldData={fieldData} formik={formik} />
      </div>
      <div>
        <div
          className={styles.messagesContainer}
          aria-live="polite"
          id="form-messages"
          role="status"
        >
          <FormSuccess />
          <FormError />
          {formSubmitted && (
            <Button
              onClick={resetForm}
              type="reset"
              variant="plain-text"
              className={classNames({
                [styles.successContainerLegacy]: !showPortalUXOverhaul,
                [styles.successContainer]: showPortalUXOverhaul,
              })}
              color={showPortalUXOverhaul ? 'kale' : 'carrot'}
              underline
            >
              Write Another Message
            </Button>
          )}
        </div>
        {!formSubmitted && (
          <div className={styles.buttonContainer}>
            <Button
              aria-disabled={formik.isSubmitting}
              variant={
                showPortalUXOverhaul
                  ? 'primary-mini-kale-experimental'
                  : 'primary'
              }
              className={classNames({
                [styles.submitButtonLegacy]: !showPortalUXOverhaul,
              })}
              type="submit"
              aria-controls="form-messages"
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};
