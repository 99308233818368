import { track } from '../track';
import { resources_page_toggled_accordion } from './eventNames';

/**
 * Send a tracking event for when a open or close an accordion on the resources page.
 */

export interface TrackResourcesPageToggleAccordionArgs {
  title: string;
  action: 'opened' | 'closed';
}

export function trackResourcesPageToggleAccordion({
  title,
  action,
}: TrackResourcesPageToggleAccordionArgs) {
  track({
    eventName: resources_page_toggled_accordion,
    eventProps: {
      title,
      action,
    },
  });
}
