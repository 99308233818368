import type { FC, ReactElement } from 'react';

import type { RouteProps } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router-dom';

import * as pagePaths from '../../constants/pagePaths';
import { useVetDetails } from '../../graphql/hooks';

export interface AttestedAtSignupRoute extends RouteProps {
  children: ReactElement;
}

export const AttestedAtSignupRoute: FC<AttestedAtSignupRoute> = ({
  children,
}) => {
  const { data, loading } = useVetDetails();

  const { pathname } = useLocation();

  if (pathname === pagePaths.PATH_DASHBOARD) {
    return children;
  }
  if (loading) {
    return;
  }
  if (data?.vetDetails?.vet?.attestedAtSignup) {
    return children;
  }
  return <Redirect to={pagePaths.PATH_DASHBOARD} />;
};
