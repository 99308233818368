import { useCallback, useState } from 'react';

import type { ToastProps } from '@farmersdog/corgi-x';
import {
  ActivityIndicator,
  Grid,
  GridItem,
  Link,
  Text,
  Toast,
  ToastSentiment,
} from '@farmersdog/corgi-x';
import { PracticeSelector } from '../../../../components';
import { TrialDiscountsContainer } from '../TrialDiscountsContainer';

import { useValidatePractice } from '../../../../graphql/hooks/useValidatePractice';

import type {
  FoundPractice,
  OrganizationInput,
} from '../../../../graphql/types';

import config from '../../../../config';

import { PATH_PRIVACY } from '@farmersdog/constants/paths';

import styles from './PracticeSearch.module.css';
import { validatedOrganizationToInput } from '../../../../utils';

interface PracticeSearchProps {
  onAddVetPractice?: () => void;
  handleSelectedOrganizationChange: (
    newOrganization: OrganizationInput
  ) => void;
  searchTerm?: string;
  selectedPractice?: FoundPractice;
  searchResults: FoundPractice[];
  setSearchTerm: (arg: string) => void;
  setSearchResults: (arg: FoundPractice[]) => void;
  setSelectedPractice: (arg: FoundPractice) => void;
}

const toastErrorTitle =
  'We were unable to retrieve your practice. Please try again.';

export function PracticeSearch({
  handleSelectedOrganizationChange,
  onAddVetPractice,
  searchTerm = '',
  setSearchTerm,
  selectedPractice,
  searchResults = [],
  setSearchResults,
  setSelectedPractice,
}: PracticeSearchProps) {
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });

  const { validatePractice, loading: validatePracticeLoading } =
    useValidatePractice();

  const onSelectedPracticeChange = useCallback(
    async (practice: FoundPractice, searchString: string) => {
      setSelectedPractice(practice);
      setSearchTerm(searchString);

      try {
        const { data } = await validatePractice({
          variables: {
            practice: {
              address: practice.formattedAddress,
              name: practice.name,
            },
          },
        });
        if (!data?.validatePractice) {
          setToast({
            message: toastErrorTitle,
            sentiment: ToastSentiment.Negative,
            isOpen: true,
          });
          return;
        }

        handleSelectedOrganizationChange(
          validatedOrganizationToInput(data.validatePractice)
        );
      } catch {
        setToast({
          message: toastErrorTitle,
          sentiment: ToastSentiment.Negative,
          isOpen: true,
        });
      }
    },
    [
      handleSelectedOrganizationChange,
      setSearchTerm,
      setSelectedPractice,
      validatePractice,
    ]
  );

  const loading = validatePracticeLoading;

  if (loading)
    return (
      <Grid className={styles.loadingContainer}>
        <ActivityIndicator mode="dark" />
      </Grid>
    );

  return (
    <div className={styles.container}>
      <TrialDiscountsContainer>
        <Grid justifyContent="flex-start" rowGap={{ xs: 'md', lg: 'lg' }}>
          <Toast
            {...toast}
            autoClose
            onClose={() => setToast({ isOpen: false })}
          />
          <GridItem alignItems="center">
            <Text className={styles.title} variant="article-16">
              Search for your practice using the form below. We will use this
              information to verify your status as a vet professional.
            </Text>
          </GridItem>
          <GridItem>
            <PracticeSelector
              selectedPractice={selectedPractice}
              onPracticeSelected={onSelectedPracticeChange}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              onAddVetPracticeAction={onAddVetPractice}
            />
          </GridItem>
        </Grid>
      </TrialDiscountsContainer>
      <Link
        className={styles.link}
        href={`${config.get('app.website.url')}${PATH_PRIVACY}`}
        target="_blank"
      >
        <Text variant="heading-16" color="kale-2" bold>
          Privacy Policy
        </Text>
      </Link>
    </div>
  );
}
