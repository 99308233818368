import { downloadable_info_click_on_download_link } from './eventNames';
import { track } from '../track';

/**
 * Send a tracking event for when a user downloads a document from downloads screen
 *
 * @param title - Document title
 */

export interface TrackDownloadableInfoValues {
  title: string;
}

export function trackDownloadableInfo({ title }: TrackDownloadableInfoValues) {
  track({
    eventName: downloadable_info_click_on_download_link,
    eventProps: {
      title,
    },
  });
}
