import { Button, Divider, Text } from '@farmersdog/corgi-x';
import type { ValidatePracticeQuery } from '../../../../graphql/types';
import styles from './OrganizationSummary.module.css';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';

interface OrganizationSummaryProps {
  practice?: ValidatePracticeQuery['validatePractice'];
  onBackToSearch: () => void;
  onConfirm: () => void;
  disableSubmit: boolean;
}

export const OrganizationSummary = ({
  disableSubmit,
  practice,
  onBackToSearch,
  onConfirm,
}: OrganizationSummaryProps) => {
  const name = practice?.addressComponents?.name ?? '';
  const address = practice?.formattedAddress ?? '';
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  return (
    <>
      <Button
        variant="plain-text"
        className={styles.backToSearchButton}
        weight="normal"
        underline
        color={showPortalUXOverhaul ? 'kale' : 'carrot'}
        onClick={onBackToSearch}
      >
        Back to Search
      </Button>
      <section className={styles.container}>
        <Text as="h2" variant="heading-16" bold color="blueberry-3">
          Name
        </Text>
        <Text as="span" variant="heading-16" color="charcoal-3">
          {name}
        </Text>

        <Text
          as="h2"
          variant="heading-16"
          bold
          color="blueberry-3"
          className={styles.addressTitle}
        >
          Address
        </Text>
        <Text as="span" variant="heading-16" color="charcoal-3">
          {address}
        </Text>
      </section>
      <Divider
        width={1}
        color={showPortalUXOverhaul ? 'Blueberry1' : 'Charcoal0'}
      />
      <Button
        onClick={onConfirm}
        aria-disabled={disableSubmit}
        variant={
          showPortalUXOverhaul ? 'primary-mini-kale-experimental' : 'primary'
        }
        className={styles.button}
      >
        Save Changes
      </Button>
    </>
  );
};
