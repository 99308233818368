import type { GraphQLFormattedError } from 'graphql';

import type { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { errorCodes } from '../utils/errorCodes';

import * as AuthService from '../../auth';

export const createErrorMiddleware = (): ApolloLink => {
  return onError(({ graphQLErrors }) => {
    if (!graphQLErrors) {
      return;
    }

    const unauthenticatedError = graphQLErrors.find(isUnauthenticatedError);

    if (!unauthenticatedError) {
      return;
    }

    void AuthService.signout()
      .finally(() => {
        location.reload();
      })
      .catch(() => {
        // Do nothing
      });
  });
};

function isUnauthenticatedError(error: GraphQLFormattedError) {
  return error.extensions?.code === errorCodes.UNAUTHENTICATED;
}
