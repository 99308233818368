import type { FC } from 'react';
import { PublicPage, SignupForm } from '../../components';

import { useSignupPracticeSearchMode } from '../../hooks';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const SignupConference: FC = () => {
  useSignupPracticeSearchMode();

  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <PublicPage
      blueberryBackground={showPortalUXOverhaul}
      hideAppBar
      hideFooter
    >
      <SignupForm isConferenceModeOn />
    </PublicPage>
  );
};
