import { useFeature } from '@farmersdog/ab-testing';
import type { AnonymousFeatures, UserFeatures } from '../../features';
import { FeatureName, OnOffTreatments } from '../../features';

export function useLoggedOutPage() {
  const { treatment } = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_HOMEPAGE_LOGGED_OUT_PORTAL_NAVIGATION, {});

  const showImprovedLoggedOutPage = treatment === OnOffTreatments.on;
  return {
    showImprovedLoggedOutPage,
  };
}
