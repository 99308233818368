import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components';
interface UpdatePasswordProps {
  disableUpdatePassword?: boolean;
  onUpdatePassword: () => void;
}

export const UpdatePassword = ({
  disableUpdatePassword,
  onUpdatePassword,
}: UpdatePasswordProps) => {
  return (
    <Card>
      <Grid rowGap="md">
        <GridItem md={9}>
          <Text variant="heading-28" bold color="blueberry-3">
            Password
          </Text>
        </GridItem>
        <GridItem md={3}>
          <Button
            variant="secondary-mini-kale-experimental"
            onClick={
              !disableUpdatePassword ? () => onUpdatePassword() : undefined
            }
            aria-disabled={disableUpdatePassword}
          >
            Update Password
          </Button>
        </GridItem>
      </Grid>
    </Card>
  );
};
