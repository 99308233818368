import { AccountPage } from '../../components/AccountPage';

import styles from './Resources.module.css';
import type { ResponsiveProps } from '../../constants';
import { ResourcesResponsiveContentLegacy } from './ResourcesResponsiveContentLegacy/ResourcesResponsiveContentLegacy';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { ResourcesContent } from './ResourcesContent/ResourcesContent';

export const ResourcesResponsive = ({ isMobile }: ResponsiveProps) => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <AccountPage>
      {!showPortalUXOverhaul && (
        <ResourcesResponsiveContentLegacy isMobile={isMobile} />
      )}

      {showPortalUXOverhaul && <ResourcesContent isMobile={isMobile} />}
    </AccountPage>
  );
};

export const Resources = () => {
  return (
    <>
      <div className={styles.mobile}>
        <ResourcesResponsive isMobile={true} />
      </div>
      <div className={styles.desktop}>
        <ResourcesResponsive isMobile={false} />
      </div>
    </>
  );
};
