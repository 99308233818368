import type * as Apollo from '@apollo/client';

import type {
  CreateVetWithTempCredentialsMutationVariables,
  CreateVetWithTempCredentialsMutation,
} from '../types';
import createVetWithTempCredentials from '../mutations/create-vet-with-temp-credentials.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  CreateVetWithTempCredentialsMutation,
  CreateVetWithTempCredentialsMutationVariables
>;

export function useCreateVetWithTempCredentials(options?: Options) {
  const [createVetWithTempCredentialsMutation, { loading, error }] =
    useMutation<
      CreateVetWithTempCredentialsMutation,
      CreateVetWithTempCredentialsMutationVariables
    >(createVetWithTempCredentials, options);

  return { createVetWithTempCredentialsMutation, loading, error };
}
