import { Button, Text } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';
import { CheckboxWithText } from '../CheckboxWithText';
import type { usePersonalInfoForm } from './hooks';
import { PersonalInformationFormFields } from './PersonalInformationForm/constants';
import { getAbsoluteLink } from '../../utils';
import styles from './TermsAndConditionsSection.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
interface TermsAndConditionsSectionProps {
  personalInfoForm: ReturnType<typeof usePersonalInfoForm>['personalInfoForm'];
}

export function TermsAndConditionsSection({
  personalInfoForm,
}: TermsAndConditionsSectionProps) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <>
      <CheckboxWithText
        maxWidth
        checked={
          personalInfoForm.values[
            PersonalInformationFormFields.acceptedPrivacyPolicy
          ]
        }
        onChange={e => {
          personalInfoForm.handleChange(e);
        }}
        onBlur={personalInfoForm.handleBlur}
        error={
          personalInfoForm.errors[
            PersonalInformationFormFields.acceptedPrivacyPolicy
          ]
        }
        isTouched={
          personalInfoForm.touched[
            PersonalInformationFormFields.acceptedPrivacyPolicy
          ]
        }
        fieldName={PersonalInformationFormFields.acceptedPrivacyPolicy}
        labelContent={<>I agree to the Terms of Use</>}
      />

      <Text
        variant="heading-12"
        topSpacing="sm"
        className={styles.subtitleLink}
      >
        I have read and agree to The Farmer’s Dog’s{' '}
        <Button
          variant="plain-text"
          type="anchor"
          href={getAbsoluteLink(paths.PATH_TERMS)}
          target="_blank"
          weight="normal"
          color={showPortalUXOverhaul ? 'kale' : 'carrot'}
          size="sm"
        >
          Terms of Use
        </Button>
      </Text>

      <CheckboxWithText
        maxWidth
        checked={
          personalInfoForm.values[
            PersonalInformationFormFields.attestedAtSignup
          ]
        }
        onChange={e => {
          personalInfoForm.handleChange(e);
        }}
        onBlur={personalInfoForm.handleBlur}
        error={
          personalInfoForm.errors[
            PersonalInformationFormFields.attestedAtSignup
          ]
        }
        isTouched={
          personalInfoForm.touched[
            PersonalInformationFormFields.attestedAtSignup
          ]
        }
        fieldName={PersonalInformationFormFields.attestedAtSignup}
        labelContent={<>I attest to the accuracy of my information</>}
      />
      <Text
        variant="heading-12"
        topSpacing="sm"
        className={styles.subtitleLink}
      >
        I attest that the information I have provided to gain access to this
        site, intended strictly for veterinary professionals, is true and
        accurate to the best of my knowledge. I understand that if this
        information is found to be untrue, the company reserves the right to
        change or deny access to the site and any benefits contained therein.
      </Text>
    </>
  );
}
