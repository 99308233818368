import type { FormFieldArray } from '../../FormFields';
import { FieldType } from '../../FormFields';

export enum ForgotPasswordFormFields {
  email = 'email',
}

export const ForgotPasswordFormFieldsData: FormFieldArray<ForgotPasswordFormFields> =
  [
    {
      name: ForgotPasswordFormFields.email,
      type: FieldType.Text,
      label: 'Enter email address',
      inputMode: 'email',
    },
  ];
