import type { FormikProps } from 'formik';
import type { FormFieldArray } from '../../FormFields';
import { FormFields } from '../../FormFields';
import type {
  PersonalInformationConferenceFormValues,
  PersonalInformationFormValues,
} from './constants';
import styles from './PersonalInformationForm.module.css';

import { useMemo } from 'react';
import classNames from 'classnames';
interface PersonalFormProps<T extends FormFieldArray> {
  form: FormikProps<
    PersonalInformationFormValues | PersonalInformationConferenceFormValues
  >;
  fieldData: T;
  children?: JSX.Element | null;
  withForm?: boolean;
  isConferenceModeOn?: boolean;
}

export function PersonalInformationForm<T extends FormFieldArray>({
  fieldData,
  form,
  children,
  withForm = true,
  isConferenceModeOn = false,
}: PersonalFormProps<T>) {
  const body = useMemo(() => {
    if (withForm) {
      return (
        <form
          id="master-search-form"
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            form.handleSubmit(e);
          }}
        >
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={fieldData} formik={form} />
        </form>
      );
    }
    return (
      <div className={styles.form}>
        {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
        <FormFields fieldData={fieldData} formik={form} />
      </div>
    );
  }, [fieldData, form, withForm]);
  return (
    <div
      className={classNames(styles.container, {
        [styles.addSpacing]: !isConferenceModeOn,
      })}
    >
      {body}
      {children}
    </div>
  );
}
