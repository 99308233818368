import { Picture } from '../Picture';

import styles from './MemberCardForOurTeam.module.css';
import type { MemberCardPropsWithCallback } from '.';
import { Grid, GridItem, Text, Button } from '@farmersdog/corgi-x';

const DEFAULT_TEXT_LINES_DESCRIPTION = 3;

export function MemberCardForOurTeam({
  id,
  name,
  education,
  position,
  sources,
  alt,
  howTheyHelpDogsText,
  howTheyHelpDogsTextLines,
  onReadMore,
}: MemberCardPropsWithCallback) {
  const handleClick = () => {
    onReadMore(id);
  };

  return (
    <>
      <Grid>
        <GridItem>
          <Grid columnGap="md" className={styles.profileAndTitle}>
            <GridItem xs={4} className={styles.profile}>
              <Picture width="96" height="96" sources={sources} alt={alt} />
            </GridItem>
            <GridItem xs={8} className={styles.title}>
              <Text
                className={styles.name}
                as="p"
                bold
                color="charcoal-3"
                fontStyle="normal"
                variant="heading-16"
              >
                {name}
              </Text>
              {education && (
                <Text
                  className={styles.education}
                  as="p"
                  bold
                  color="charcoal-3"
                  fontStyle="normal"
                  variant="heading-16"
                >
                  {education}
                </Text>
              )}
              <Text
                className={styles.position}
                as="p"
                variant="heading-16"
                color="charcoal-3"
              >
                {position}
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid
        className={styles.shortDescription}
        style={{
          // property to set how many lines we want to show with ellipsis
          WebkitLineClamp:
            howTheyHelpDogsTextLines ?? DEFAULT_TEXT_LINES_DESCRIPTION,
        }}
      >
        <Text as="span" variant="article-16" color="charcoal-3">
          {howTheyHelpDogsText}
        </Text>
      </Grid>
      <Grid topSpacing="sm">
        <Text variant="article-16" bold>
          <Button
            variant="plain-text"
            onClick={handleClick}
            color="kale"
            underline
          >
            Read More
          </Button>
        </Text>
      </Grid>
    </>
  );
}
