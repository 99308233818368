import type { TextVariants } from '@farmersdog/corgi-x';
import {
  Text,
  Grid,
  GridItem,
  PageWidth,
  Nowrap,
  Button,
} from '@farmersdog/corgi-x';

import styles from './SolutionsSection.module.css';

import { Solution } from './components/Solution';
import { solutionData } from './solutionData';
import * as pagePaths from '../../../constants/pagePaths';
import { trackLandingPageClickedViewClinicalRecommendations } from '../../../analytics/events';

interface ResponsiveSolutionsSectionProps {
  isMobile?: boolean;
  titleVariant: TextVariants;
  descriptionVariant: TextVariants;
}

export function ResponsiveSolutionsSection({
  isMobile,
  titleVariant,
  descriptionVariant,
}: ResponsiveSolutionsSectionProps) {
  return (
    <PageWidth>
      <Text
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="md"
        color="blueberry-3"
        variant={titleVariant}
      >
        Our Recipes
        {isMobile && <br />} Provide Options
      </Text>
      <Text
        as="p"
        color="charcoal-3"
        variant={descriptionVariant}
        className={styles.description}
      >
        All of our food is formulated by board-certified nutritionists to be
        complete and balanced according to AAFCO standards, verified by a
        multi-year feeding study, and clinically proven by third-party research
        to be highly digestible and palatable. Each recipe is a blend of
        human-grade meat and vegetables, and we have a variety of options with
        different ingredients and macronutrient profiles to suit dogs with
        <Nowrap> diverse needs.</Nowrap>
      </Text>
      <Button
        type="link"
        to={pagePaths.PATH_DOWNLOADS}
        onClick={() => {
          trackLandingPageClickedViewClinicalRecommendations();
        }}
        className={styles.button}
      >
        View Patient-Specific Clinical Options
      </Button>
      <Grid
        rowGap="3xl"
        flexDirection={{
          xs: 'column',
          lg: 'row',
        }}
        columnGap={{
          lg: 'md',
        }}
        className={styles.solutionsContainer}
      >
        {solutionData.map(({ image, title, content, arialLabel }, idx) => (
          <GridItem
            justifyContent="center"
            className={styles.individualSolutionContainer}
            lg={3}
            key={idx}
          >
            <Solution
              image={image}
              title={title}
              content={content}
              arialLabel={arialLabel}
            />
          </GridItem>
        ))}
      </Grid>
    </PageWidth>
  );
}

export function SolutionsSection() {
  return (
    <section className={styles.section}>
      <div className={styles.mobile}>
        <ResponsiveSolutionsSection
          isMobile
          titleVariant="heading-28"
          descriptionVariant="article-16"
        />
      </div>
      <div className={styles.desktop}>
        <ResponsiveSolutionsSection
          titleVariant="heading-52"
          descriptionVariant="article-20"
        />
      </div>
    </section>
  );
}
