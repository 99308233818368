import { track } from '../track';
import { clicked_discount_eligibility } from './eventNames';

/**
 * Send a tracking event for when a user clicked to see who is eligible for subscription discount
 */

export function trackClickedDiscountEligibility() {
  track({
    eventName: clicked_discount_eligibility,
  });
}
