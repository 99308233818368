import type { FormFieldArray } from '../FormFields';
import { FieldType } from '../FormFields';

export enum ContactUsFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  message = 'message',
}

export const contactUsFormFieldsData: FormFieldArray<ContactUsFormFields> = [
  {
    name: ContactUsFormFields.firstName,
    type: FieldType.Text,
    label: 'First Name',
  },
  {
    name: ContactUsFormFields.lastName,
    type: FieldType.Text,
    label: 'Last Name',
  },
  {
    name: ContactUsFormFields.email,
    type: FieldType.Text,
    label: 'Contact Email',
    inputMode: 'email',
  },
  {
    name: ContactUsFormFields.phone,
    type: FieldType.Text,
    label: 'Contact Phone Number (Optional)',
    inputMode: 'tel',
  },
  {
    name: ContactUsFormFields.message,
    type: FieldType.TextArea,
    label: 'Message',
    className: 'inputFullWidth',
  },
];
