import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getMasterPracticeUrl } from '../utils';
import * as paths from '../constants/pagePaths';

export function useSignupPracticeSearchMode() {
  const history = useHistory();

  useEffect(() => {
    const { pathname, search, hash } = history.location;
    if (!pathname.includes(paths.PATH_SIGNUP)) return;
    let newPathWithPractice = getMasterPracticeUrl({
      pathname,
      search,
      signupMode: 'search',
    });
    newPathWithPractice = `${newPathWithPractice}${hash ?? ''}`;
    history.replace(newPathWithPractice);
  }, [history]);

  return history;
}
