import { track } from '../track';
import { landing_page_clicked_sticky_nav_link } from './eventNames';
import type { SectionName } from '../../pages/Home/Hero/nav-items';

/**
 * Send a tracking event for when a user clicks on any link in the sticky navbar.
 */

export interface TrackLandingPageClickedStickyNavLinkArgs {
  title: SectionName;
}

export function trackLandingPageClickedStickyNavLink({
  title,
}: TrackLandingPageClickedStickyNavLinkArgs) {
  track({
    eventName: landing_page_clicked_sticky_nav_link,
    eventProps: {
      title,
    },
  });
}
