import { arrayToOptions } from '../../../../utils';
import {
  howDidYouHearAboutUsOptions,
  positionOptions,
} from '../../../../constants';

import type { FormFieldArray } from '../../../FormFields';
import { FieldType } from '../../../FormFields';

export enum PersonalInformationFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  position = 'position',
  acceptedPrivacyPolicy = 'acceptedPrivacyPolicy',
  attestedAtSignup = 'attestedAtSignup',
  howDidYouHearAboutUs = 'howDidYouHearAboutUs',
  password = 'password',
}

export const conferencePersonalInformationFormFieldsData: FormFieldArray<PersonalInformationFormFields> =
  [
    {
      name: PersonalInformationFormFields.firstName,
      type: FieldType.Text,
      label: 'First Name',
    },
    {
      name: PersonalInformationFormFields.lastName,
      type: FieldType.Text,
      label: 'Last Name',
    },
    {
      name: PersonalInformationFormFields.email,
      type: FieldType.Text,
      label: 'Contact Email',
      inputMode: 'email',
    },
    {
      name: PersonalInformationFormFields.position,
      type: FieldType.Select,
      label: 'Role',
      options: positionOptions,
    },
  ];

export const standardPersonalInformationFieldsData: FormFieldArray<PersonalInformationFormFields> =
  [
    ...conferencePersonalInformationFormFieldsData,
    {
      name: PersonalInformationFormFields.password,
      type: FieldType.Password,
      label: 'Password (minimum 6 characters)',
    },
    {
      name: PersonalInformationFormFields.howDidYouHearAboutUs,
      type: FieldType.Select,
      label: 'How did you hear about us?',
      options: arrayToOptions(howDidYouHearAboutUsOptions),
    },
  ];
