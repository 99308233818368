import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import type { DownloadListData } from './downloadListData';
import { downloadListData, nutritionGuide } from './downloadListData';

import { trackDownloadableInfo } from '../../../../analytics/events';

import styles from './DownloadList.module.css';

export function DownloadList() {
  return (
    <>
      <div className={styles.container}>
        <ul className={styles.list}>
          {generateDownloadList(nutritionGuide)}
          <div className={styles.recipeList}></div>
          {generateDownloadList(downloadListData)}
        </ul>
      </div>
    </>
  );
}

function generateDownloadList(data: DownloadListData[]) {
  return data.map(({ title, description, file }, i) => {
    return (
      <li
        key={`download-${i}`}
        className={styles.listItem}
        aria-label={`${title} file details`}
      >
        <div>
          <Text variant="heading-16" bold>
            {title}
          </Text>
          <Text
            as="p"
            variant="article-12"
            color="charcoal-3"
            className={styles.description}
          >
            {description}
          </Text>
          <Text variant="heading-12">
            <Link
              target="_blank"
              href={file}
              onClick={() => trackDownloadableInfo({ title })}
              className={styles.downloadLink}
              aria-label={`View ${title} PDF`}
            >
              View PDF
            </Link>
          </Text>
        </div>
      </li>
    );
  });
}
