import { FieldType } from '../FormFields';
import type { FormFieldArray } from '../FormFields';

export enum LoginSetNewPasswordFormFields {
  email = 'email',
  temporaryCode = 'temporaryCode',
  password = 'password',
}

export const loginSetNewPasswordFormFields: FormFieldArray<LoginSetNewPasswordFormFields> =
  [
    {
      name: LoginSetNewPasswordFormFields.email,
      type: FieldType.Text,
      label: 'Email Address',
      inputMode: 'email',
    },
    {
      name: LoginSetNewPasswordFormFields.temporaryCode,
      type: FieldType.Password,
      label: 'Temporary code',
    },
    {
      name: LoginSetNewPasswordFormFields.password,
      type: FieldType.Password,
      label: 'Choose a password',
    },
  ];
