import type { ReactNode } from 'react';
import { Link } from '@farmersdog/corgi-x';
import { EMAIL_VET } from '@farmersdog/constants/emails';
import { trackClickedSupport } from '../analytics/events';

interface EmailSupportButtonProps {
  subject: string;
  children: ReactNode;
}

export function EmailSupportButton({
  subject,
  children,
}: EmailSupportButtonProps) {
  const toEmail = `mailto:${EMAIL_VET}?subject=${subject}`;

  const handleEmailSupportClick = () => {
    trackClickedSupport();
  };

  return (
    <Link
      to={toEmail}
      type="link"
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleEmailSupportClick}
    >
      {children}
    </Link>
  );
}
