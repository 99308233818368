import NutritionGuideThumbnailPng from '../assets/NutritionGuideThumbnail.png';
import NutritionGuideThumbnailJpg from '../assets/NutritionGuideThumbnail.jpg';
import NutritionGuideThumbnailWebp from '../assets/NutritionGuideThumbnail.webp';
import vetNutritionGuideFile from '../../../../../static/pdf/TFD_Vet_Nutrition_Guide_2024.pdf';
import type { Pdf } from '../PdfSection/pdfSectionData';

export const nutritionGuideSectionData: Pdf = {
  title: '2024 Nutrition Guide',
  description:
    'Our comprehensive review of The Farmer’s Dog nutritional info, safety standards, and feeding instructions.',
  link: vetNutritionGuideFile,
  thumbnailSources: [
    NutritionGuideThumbnailWebp,
    NutritionGuideThumbnailJpg,
    NutritionGuideThumbnailPng,
  ],
  urlParam: 'vet-nutrition-guide',
};
