import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Calendar } from './Calendar';
import styles from './Event.module.css';
import type { VetEvent } from '../../../../data/eventsAndRecordingsData';

import classNames from 'classnames';
import { usePublicPageFeatures } from '../../../../abTesting/PublicPageFeatures';
interface EventProps {
  vetEvent: VetEvent;
  leftBorder?: boolean;
  breakIconLine?: boolean;
}

const getId = (name: string) =>
  `event-${name?.replaceAll(' ', '-').replaceAll('’', '').toLowerCase()}-link`;

export const Event = ({
  vetEvent: {
    day,
    type,
    month,
    name,
    speaker,
    hourLocation,
    link,
    linkText,
    linkDisable = false,
    linkOnClick = () => {},
    icon: Icon,
    description,
  },
  leftBorder = false,
  breakIconLine = false,
}: EventProps) => {
  const hasCalendar = month && day;
  const hasIcon = !hasCalendar && Icon;
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <>
      <Grid
        flexDirection={breakIconLine ? 'column' : 'row'}
        gap={breakIconLine ? 'sm' : 'none'}
        className={styles.container}
      >
        {leftBorder && (
          <Divider
            width={1}
            color={showPortalUXOverhaul ? 'Blueberry1' : 'Kale1'}
            orientation="vertical"
          />
        )}
        <GridItem
          className={classNames(styles.calendar, {
            [styles.noPadding]: breakIconLine,
            [styles.noIcon]: !hasCalendar && !hasIcon,
          })}
        >
          {hasCalendar && <Calendar month={month} day={day} />}
          {hasIcon && <Icon />}
        </GridItem>
        <GridItem>
          <Grid
            flexDirection="column"
            className={styles.infoContainer}
            justifyContent="space-between"
            gap={showPortalUXOverhaul ? 'xs' : 'none'}
          >
            <Text
              variant="heading-16"
              color={showPortalUXOverhaul ? 'Blueberry2' : 'kale-2'}
              bold
            >
              {type}
            </Text>
            <Text variant="heading-16" bold>
              {name}
            </Text>
            <Text variant="heading-16" color="charcoal-2">
              {hourLocation}
            </Text>
            <Text variant="heading-16" color="charcoal-2">
              {description}
            </Text>
            <Text variant="heading-16" color="charcoal-2">
              {speaker}
            </Text>
            {(link || linkText) && (
              <Button
                aria-disabled={linkDisable}
                onClick={linkOnClick}
                type="anchor"
                href={link ?? '#'}
                target="_blank"
                variant="plain-text"
                color={showPortalUXOverhaul ? 'kale' : 'carrot'}
                className={styles.link}
                id={getId(name)}
                underline
              >
                {linkText ?? link}
              </Button>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </>
  );
};
