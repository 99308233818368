import { hydrateRoot, createRoot } from 'react-dom/client';
import { polyfillSmoothScroll } from '@farmersdog/corgi';

import { ClientApp } from './ClientApp';

import { setupAmplify } from './services/auth/utils/setup';
import config from './config';
import { RenderMode } from './config/schema';
import type { TfdLoggerConfig } from '@farmersdog/logger';
import { Logger, loggerInit } from '@farmersdog/logger';

loggerInit({
  level: config.get('app.log.level') as TfdLoggerConfig['level'],
  datadog: {
    enabled: config.get('app.datadogRum.enabled'),
    clientToken: config.get('app.datadogRum.clientToken'),
    service: 'vet-portal',
    env: config.get('env'),
    version: config.get('app.version'),
    forwardErrorsToLogs: false,
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw Error('no root');
}

setupAmplify();
polyfillSmoothScroll();

const renderMode = config.get('app.renderMode');

const log = new Logger('app:clientHydration');

// Since we do not build in dev mode, using hydrateRoot in dev mode always results in mismatch and React automatically falls back to client side rendering.
// We can configure client side rendering ourselves for local dev.
if (renderMode === RenderMode.hydrate) {
  hydrateRoot(rootElement, <ClientApp />, {
    onRecoverableError: (error, errorInfo) => {
      console.error(error);
      console.error(errorInfo);
      log.error('Error occurred during hydration', {
        error,
        errorInfo,
      });
    },
  });
} else {
  const root = createRoot(rootElement);
  root.render(<ClientApp />);
}
