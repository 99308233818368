import { LoginSetNewPasswordFormFields } from './loginSetNewPasswordFormFields';

export interface LoginSetNewPasswordFormInitialValues {
  [LoginSetNewPasswordFormFields.email]: string;
  [LoginSetNewPasswordFormFields.temporaryCode]: string;
  [LoginSetNewPasswordFormFields.password]: string;
}

export const loginSetNewPasswordFormInitialValues: LoginSetNewPasswordFormInitialValues =
  {
    [LoginSetNewPasswordFormFields.email]: '',
    [LoginSetNewPasswordFormFields.temporaryCode]: '',
    [LoginSetNewPasswordFormFields.password]: '',
  };
