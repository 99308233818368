import { useState } from 'react';
import type { ToastProps } from '@farmersdog/corgi-x';
import { ToastSentiment } from '@farmersdog/corgi-x';

export function useSignupToast() {
  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });

  function showErrorToast(message: string) {
    setToast({
      message,
      sentiment: ToastSentiment.Negative,
      isOpen: true,
    });
  }

  function clearToast() {
    setToast({
      message: '',
      sentiment: ToastSentiment.Neutral,
      isOpen: false,
    });
  }
  return { toast, showErrorToast, clearToast };
}
