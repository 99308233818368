import type { SignInOutput } from 'aws-amplify/auth';
import {
  fetchAuthSession,
  confirmResetPassword,
  resetPassword as resetPasswordAmplify,
  signIn,
  signOut,
  signUp,
  fetchUserAttributes,
  deleteUser as deleteUserAmplify,
  confirmSignIn,
} from 'aws-amplify/auth';

import type {
  CognitoAttributes,
  CognitoAttributesSendCode,
  CognitoAttributesResetPassword,
  CognitoAuthenticatedUserResult,
  CognitoLogInSetNewPassword,
} from '../../context/constants';

import cognitoTokenStorage from '../../utils/cognito-token-storage';

const CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED =
  'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED';

export const getCurrentEmailSession = async (): Promise<string | undefined> => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.payload.email?.toString();
  } catch {
    return undefined;
  }
};

export const getCurrentAuthenticatedUser = async (): Promise<
  string | undefined
> => {
  const { tokens } = await fetchAuthSession({
    forceRefresh: true,
  });
  const token = tokens?.idToken?.toString();
  if (token) {
    cognitoTokenStorage.set(token);
  }
  return token;
};

const confirmLoginWithNewPassword = async (
  newPassword: string
): Promise<SignInOutput> => {
  return await confirmSignIn({ challengeResponse: newPassword });
};

export const loginSetNewPassword = async ({
  email,
  newPassword,
  temporaryPassword,
}: CognitoLogInSetNewPassword): Promise<
  CognitoAuthenticatedUserResult | SignInOutput
> => {
  const result = await signIn({ username: email, password: temporaryPassword });

  const isRequiredToSetNewPassword =
    !result.isSignedIn &&
    result.nextStep.signInStep === CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED;

  if (isRequiredToSetNewPassword) {
    const confirmedSignin = await confirmLoginWithNewPassword(newPassword);

    if (confirmedSignin.isSignedIn) {
      const { tokens } = await fetchAuthSession({
        forceRefresh: true,
      });
      const token = tokens?.idToken?.toString();
      if (token) {
        cognitoTokenStorage.set(token);
        const attributes = await fetchUserAttributes();
        return {
          token,
          email: attributes?.email || '',
          id: attributes.sub || '',
        };
      }
    }
  }

  return result;
};

export const login = async ({
  email,
  password,
}: CognitoAttributes): Promise<CognitoAuthenticatedUserResult | undefined> => {
  const result = await signIn({ username: email, password });

  if (result.isSignedIn) {
    const { tokens } = await fetchAuthSession({
      forceRefresh: true,
    });
    const token = tokens?.idToken?.toString();
    if (token) {
      cognitoTokenStorage.set(token);
      const attributes = await fetchUserAttributes();
      return {
        token,
        email: attributes?.email || '',
        id: attributes.sub || '',
      };
    }
  }

  return undefined;
};

export const signup = async ({ email, password }: CognitoAttributes) => {
  await signUp({
    username: email,
    password,
    options: {
      userAttributes: {
        email,
      },
    },
  });
};

export const signout = async () => {
  await signOut();
  cognitoTokenStorage.remove();
};

export const forgotPassword = async ({ email }: CognitoAttributesSendCode) => {
  await resetPasswordAmplify({ username: email });
};

export const resetPassword = async ({
  code,
  email,
  password,
}: CognitoAttributesResetPassword) => {
  await confirmResetPassword({
    username: email,
    confirmationCode: code,
    newPassword: password,
  });
};

export const deleteUser = async () => {
  await deleteUserAmplify();
};
