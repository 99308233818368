import type { PropsWithChildren, FC } from 'react';

import { AuthContextProvider } from './AuthContext';
import { LightboxProvider } from '../hooks';

export const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthContextProvider>
      <LightboxProvider>{children}</LightboxProvider>
    </AuthContextProvider>
  );
};
