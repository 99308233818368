import get from 'lodash/get';
import getProperties from './get-properties';
import type { Properties } from './server';
import { browserOnly } from '@farmersdog/utils';

import type { Path, PathValue } from 'convict';

type PathParam = Path<Properties>;
type GetReturn<K extends PathParam> = K extends null | undefined
  ? Properties
  : K extends Path<Properties>
    ? PathValue<Properties, K>
    : never;

declare global {
  interface Window {
    __CONVICT_ENV__: string;
  }
}

const fallbackEnvironment = 'development';

export default {
  get<K extends PathParam>(path: K): GetReturn<K> {
    const properties = getProperties();

    const env =
      browserOnly(
        window => window.__CONVICT_ENV__,
        () => process.env.CONVICT_ENV
      ) ?? fallbackEnvironment;

    return get(
      properties,
      `${String(env)}.${String(path)}`
    ) as unknown as GetReturn<K>;
  },
};
