import { Button, PageWidth, Text } from '@farmersdog/corgi-x';

import * as pagePaths from '../../../constants';

import styles from './BackedByScienceSection.module.css';
import { SectionId } from '../Hero/nav-items';
import { trackLandingPageClickedExploreStudiesAndResources } from '../../../analytics/events';

interface ResponsiveBackedByScienceSectionProps {
  isMobile?: boolean;
}

const ReponsiveBreakLine = ({
  isMobile,
}: ResponsiveBackedByScienceSectionProps) => {
  if (!isMobile) {
    return <br />;
  }
  return null;
};

export function ResponsiveBackedByScienceSection({
  isMobile,
}: ResponsiveBackedByScienceSectionProps) {
  return (
    <PageWidth className={styles.section}>
      <Text
        bold
        as="h2"
        color="blueberry-3"
        bottomSpacing="md"
        variant={isMobile ? 'heading-28' : 'heading-52'}
      >
        Backed by Science
      </Text>
      <Text as="p" variant={isMobile ? 'article-16' : 'article-20'}>
        Like you, we’re driven by science. We know there’s endless information
        available on <ReponsiveBreakLine isMobile={isMobile} />
        pet food and health, so we&apos;ve compiled some useful information and
        studies to get <ReponsiveBreakLine isMobile={isMobile} /> you started.
        Log in to our portal to see the research and learn more.
      </Text>
      <Button
        type="link"
        to={pagePaths.PATH_DOWNLOADS}
        className={styles.button}
        onClick={() => {
          trackLandingPageClickedExploreStudiesAndResources();
        }}
      >
        Explore Studies & Resources
      </Button>
    </PageWidth>
  );
}

export function BackedByScienceSection() {
  return (
    <section className={styles.section} id={SectionId.ArticlesAndStudies}>
      <div className={styles.mobile}>
        <ResponsiveBackedByScienceSection isMobile />
      </div>
      <div className={styles.desktop}>
        <ResponsiveBackedByScienceSection />
      </div>
    </section>
  );
}
