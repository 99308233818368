import type { Logger } from '@farmersdog/logger';
import { errorCodes } from './errors';
import { isErrorWithName } from '../../../utils';

interface HandleUnexpectedAuthErrorArgs {
  error: unknown;
  logger: Logger;
  authPayload?: Record<string, unknown>;
}

export function handleUnexpectedAuthError({
  error,
  logger,
  authPayload,
}: HandleUnexpectedAuthErrorArgs) {
  const errorInfo: {
    errorName: string | undefined;
    error: string;
    authPayload?: Record<string, unknown>;
  } = {
    errorName: undefined,
    error: JSON.stringify(error),
  };

  if (isErrorWithName(error)) {
    // Do not log the actual values of auth payload for PII & data security reasons
    if (error.name === errorCodes.INVALID_PARAM && authPayload) {
      errorInfo.authPayload = Object.fromEntries(
        Object.entries(authPayload).map(([key, value]) => {
          return [key, { isTruthy: Boolean(value) }];
        })
      );
    }
    errorInfo.errorName = error.name;
  }

  logger.error('Unexpected error occurred', {
    error: errorInfo,
  });
}
