import type { ReactNode } from 'react';

import { Section } from '@farmersdog/corgi-x';

import classNames from 'classnames';

import styles from './Card.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export interface CardProps {
  children: ReactNode;
  className?: string;
}

export function Card({ children, className }: CardProps) {
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  return (
    <Section
      as="div"
      variant="card"
      className={classNames(styles.card, className, {
        [styles.sharp]: showPortalUXOverhaul,
      })}
    >
      {children}
    </Section>
  );
}
