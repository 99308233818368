import type { ReactNode } from 'react';

import { Text } from '@farmersdog/corgi-x';

import styles from './Step.module.css';

export interface StepProps {
  number: number;
  content: ReactNode;
}

export interface ResponsiveTextProps {
  content: ReactNode;
}

export function ResponsiveText({ content }: ResponsiveTextProps) {
  return (
    <>
      <Text
        className={styles.small}
        as="p"
        color="charcoal-3"
        variant="article-16"
      >
        {content}
      </Text>
      <Text
        className={styles.large}
        as="p"
        color="charcoal-3"
        variant="article-20"
      >
        {content}
      </Text>
    </>
  );
}

export function Step({ number, content }: StepProps) {
  return (
    <div className={styles.stepsBlock}>
      <Text
        as="h3"
        color="blueberry-3"
        className={styles.stepsTitle}
        bold
        variant="heading-22"
      >
        Step {number}
      </Text>
      <ResponsiveText content={content} />
    </div>
  );
}
