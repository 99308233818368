import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { getPassword } from '../utils';
import type { OrganizationType } from '../../../constants';
import {
  conferencePersonalInformationFormFieldsData,
  personalInformationConferenceFormValues,
  personalInformationFormValues,
  standardPersonalInformationFieldsData,
} from '../PersonalInformationForm/constants';
import {
  personalInformationConferenceValidationSchema,
  personalInformationValidationSchema,
} from '../PersonalInformationForm/utils';
import type {
  OrganizationDataSource,
  OrganizationInput,
  ValidatePracticeQuery,
} from '../../../graphql/types';
import type { CreateVetWithOrganizationArgs } from '../SignupForm';

interface DataToSubmit {
  validatedPractice: ValidatePracticeQuery['validatePractice'] | undefined;
  source: OrganizationDataSource;
  type: OrganizationType;
}

interface UsePersonalInfoFormArgs {
  dataToSubmit: DataToSubmit;
  isConferenceModeOn: boolean;
  submit: (args: CreateVetWithOrganizationArgs) => Promise<void>;
}

function getFormSetupValues({
  isConferenceModeOn,
}: {
  isConferenceModeOn: boolean;
}) {
  const personalInfoInitialValues = isConferenceModeOn
    ? personalInformationConferenceFormValues
    : personalInformationFormValues;
  const personalInfoFormFields = isConferenceModeOn
    ? conferencePersonalInformationFormFieldsData
    : standardPersonalInformationFieldsData;

  const personalInfoValidationSchema = isConferenceModeOn
    ? personalInformationConferenceValidationSchema
    : personalInformationValidationSchema;

  return {
    personalInfoInitialValues,
    personalInfoFormFields,
    personalInfoValidationSchema,
  };
}

export function usePersonalInfoForm({
  dataToSubmit,
  isConferenceModeOn,
  submit,
}: UsePersonalInfoFormArgs) {
  const { source, type, validatedPractice } = dataToSubmit;

  const {
    personalInfoInitialValues,
    personalInfoFormFields,
    personalInfoValidationSchema,
  } = getFormSetupValues({ isConferenceModeOn });

  const personalInfoForm = useFormik({
    initialValues: personalInfoInitialValues,
    validationSchema: personalInfoValidationSchema,
    // submit is used when submitting the form for search mode
    onSubmit: async formValues => {
      if (validatedPractice?.addressComponents) {
        const organization = {
          source,
          type,
          ...omit(validatedPractice.addressComponents, '__typename'),
          id: validatedPractice.id,
        } satisfies OrganizationInput;

        const password = getPassword({
          personalInformationValues: formValues,
          isConferenceModeOn,
        });

        await submit({
          personalInformation: formValues,
          organization,
          password,
        });
      }
    },
  });

  return { personalInfoForm, personalInfoFormFields };
}
