import { Divider, Grid, Text, GridItem } from '@farmersdog/corgi-x';
import { Card } from '../../../components';
import type { VetEvent } from '../../../data/eventsAndRecordingsData';
import { Event } from './Event';
import classNames from 'classnames';
import styles from './Events.module.css';
import Doggie from '../assets/Doggie.svg?react';
import { EventsLegacy } from './EventsLegacy';

export enum EventType {
  UpcomingEvents = 'Upcoming Events',
  PastEvents = 'Past Events',
  RequestedEvents = 'Requested Events',
}
interface EventsProps {
  isMobile?: boolean;
  eventType: EventType;
  events: VetEvent[];
  showPortalUXOverhaul?: boolean;
}

export const Events = ({
  eventType,
  events,
  isMobile,
  showPortalUXOverhaul,
}: EventsProps) => {
  const gridClassnames = classNames({
    [styles.upcomingEvents]:
      (!showPortalUXOverhaul && eventType === EventType.UpcomingEvents) ||
      (showPortalUXOverhaul && eventType === EventType.RequestedEvents),
  });

  const getShowLeftBorderLegacy = (index: number) =>
    !isMobile &&
    index === events.length - 1 &&
    eventType === EventType.UpcomingEvents;

  const getShowLeftBorder = (index: number) =>
    !isMobile &&
    index === events.length - 1 &&
    eventType === EventType.RequestedEvents;

  if (!showPortalUXOverhaul) {
    return (
      <EventsLegacy
        isMobile={isMobile}
        eventType={eventType}
        events={events}
        getShowLeftBorder={getShowLeftBorderLegacy}
        gridClassnames={gridClassnames}
      />
    );
  }

  return (
    <GridItem
      className={classNames({
        [styles.reduceGripGap]: eventType === EventType.RequestedEvents,
      })}
    >
      {eventType !== EventType.RequestedEvents && (
        <Text
          as="h3"
          variant={isMobile ? 'heading-22' : 'heading-28'}
          bold
          color="blueberry-3"
          topSpacing="none"
          bottomSpacing="lg"
        >
          {eventType}
        </Text>
      )}
      {eventType === EventType.UpcomingEvents && (
        <Grid className={styles.svgContainer}>
          <Doggie className={styles.doggieSvg} aria-label="Doggie Icon" />
        </Grid>
      )}
      <Card>
        <Grid className={gridClassnames}>
          {events.map((event, index) => (
            <Grid
              key={`${event.type}-${event.name}-${event.hourLocation}`}
              flexDirection="column"
            >
              {((isMobile && index != 0) ||
                (!isMobile &&
                  eventType !== EventType.RequestedEvents &&
                  index != 0)) && (
                <Divider
                  width={1}
                  color="Blueberry1"
                  spacing={isMobile ? 24 : 36}
                />
              )}
              <Event
                vetEvent={event}
                leftBorder={getShowLeftBorder(index)}
                breakIconLine={eventType === EventType.RequestedEvents}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
    </GridItem>
  );
};
